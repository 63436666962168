import React from "react";
import { Box, Divider, Link, Typography, useTheme } from "@mui/material";
import { info } from "../../../../../helpers/data/contact";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { AddressIcon, PhoneCallIcon } from "../../../../../entities/iconHelper";

export const Info = () => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();

  return (
    <>
      <Divider
        sx={{
          margin: theme.spacing(2, 0),
          [theme.breakpoints.down("md")]: {
            margin: theme.spacing(6, 0, 5),
          },
        }}
      />
      <Box
        display="flex"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            [theme.breakpoints.down("md")]: {
              marginBottom: theme.spacing(6),
            },
          }}
        >
          <Box
            sx={{
              marginRight: theme.spacing(2),
              [theme.breakpoints.down("md")]: {
                width: theme.spacing(6),
                height: theme.spacing(6),
                background: "#F4F4F8",
                borderRadius: theme.spacing(0.5),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <PhoneCallIcon
              sx={{
                [theme.breakpoints.down("md")]: {
                  "& >*": {
                    fill: "#F4F4F8",
                  },
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down("md")]: {
                  marginBottom: theme.spacing(0.5),
                },
              }}
            >
              {info[language].phone}
            </Typography>
            <Link
              variant="body2"
              target="_blank"
              underline="none"
              href="tel:+302111989293"
            >
              (+30) 211 1989 293
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: theme.spacing(8),
            display: "flex",
            [theme.breakpoints.down("md")]: { marginLeft: 0 },
          }}
        >
          <Box
            sx={{
              marginRight: theme.spacing(2),
              [theme.breakpoints.down("md")]: {
                width: theme.spacing(6),
                height: theme.spacing(6),
                background: "#F4F4F8",
                borderRadius: theme.spacing(0.5),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <AddressIcon />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down("md")]: {
                  marginBottom: theme.spacing(0.5),
                },
              }}
            >
              {info[language].address}
            </Typography>
            <Link
              href="https://maps.google.com/maps?q=Arkadias+3,+Μενεμένη+115+26"
              variant="body2"
              target="_blank"
              underline="none"
            >
              Arkadias 3 Ampelokipoi, Athens
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};
