import React from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    page: "tos",
  },
  dataLayerName: "PageDataLayer",
};

export const ToS = () => {
  TagManager.dataLayer(tagManagerArgs);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        margin: theme.spacing(10, "auto", 35),
        [theme.breakpoints.down(1440)]: {
          width: "60%",
        },
        [theme.breakpoints.down(1280)]: {
          width: "70%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "90%",
        },
        "& p": {
          lineHeight: "1.5rem",
          fontSize: "1rem",
          margin: theme.spacing(2, 0, 0),
        },
        "& h2": {
          margin: theme.spacing(3, 0, 0),
          lineHeight: 1.2,
          fontSize: "2rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "1.5rem",
          },
        },
        "& a": {
          color: "#8774F6",
          lineHeight: "1.5rem",
          fontSize: "1rem",
        },
        "& h1": {
          lineHeight: 1.2,
          fontSize: "2.5rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "2.25rem",
          },
        },
        "& ul": {
          margin: 0,
        },
      }}
    >
      <Typography variant="h1">Terms of Service</Typography>
      <Typography variant="body1">
        The terms and conditions set out below govern your use of the
        PriceMonitor website (pricemonitor.ai) and any PriceMonitor solution,
        product, system, application or software (together, the “Services”) made
        available to you by us. By accessing or using the Services, you signify
        that you have read, understood, and agree to be bound by these terms and
        conditions, and agree that you are responsible for compliance with any
        applicable local laws.
      </Typography>
      <Typography variant="body1">
        For these purposes, the owner and developer of the the Service is
        Mageguide P.C., a private company incorporated in Greece with its
        registered office address at Arkadias 3, 11526, Ampelokipoi Athens
        (“Mageguide P.C.” or “we” or “us” or “our”).
      </Typography>

      <Typography variant="h2">1. Definitions</Typography>
      <Typography variant="body1">
        In these terms and conditions, unless the context otherwise requires,
        the following expressions have the following meanings:
      </Typography>
      <Typography variant="body1">
        “Account” means collectively the personal information, payment
        information and credentials used by Users to access the Services;
      </Typography>
      <Typography variant="body1">
        “Agreement” means this binding agreement that shall come into effect
        between the User and Mageguide P.C. following the User’s acceptance of
        these terms and conditions;
      </Typography>
      <Typography variant="body1">
        “Client” means any organisation (business, charity, public body) whose
        employees, directors, contractors or agents access or use the Services;
      </Typography>
      <Typography variant="body1">
        “Content” means any text, graphics, images, audio, video, software, data
        compilations and any other form of information capable of being stored
        in a computer that appears on or forms part of the Services;
      </Typography>
      <Typography variant="body1">
        “Fees” means the sum of money paid by Users at monthly or yearly
        intervals to keep their Account active and to enable them to access the
        Services;
      </Typography>
      <Typography variant="body1">
        “Personal Data” means any information relating to an identifiable person
        who can be directly or indirectly identified in particular by reference
        to an identifier;
      </Typography>
      <Typography variant="body1">
        “User / Users” means any third party that accesses the Services.
      </Typography>

      <Typography variant="h2">2. Provision of the Services</Typography>
      <Typography variant="body1">
        Mageguide P.C. shall use its best and reasonable endeavours to provide
        the Services on an error-free basis and without interruption.
      </Typography>
      <Typography variant="body1">
        Notwithstanding, Mageguide P.C. does not provide any guarantee that
        provision of the Services shall be error-free or without interruption
        and reserves the right to alter or suspend provision of the Services
        without prior notice to Users. By accepting these terms and conditions
        the User acknowledges that the Services may change in form or nature at
        any time.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. shall have the right, exercisable at its sole discretion,
        to terminate provision of the Services without prior notice to Users.
      </Typography>
      <Typography variant="body1">
        Notwithstanding Mageguide P.C.’ right to perform any of the actions
        detailed in this Clause without prior notice, Mageguide P.C. shall use
        its best and reasonable endeavours to provide such notice whenever
        possible.
      </Typography>

      <Typography variant="h2">3. Access to the Services</Typography>
      <Typography variant="body1">
        The User represents and warrants that they have the authority to enter
        into the Agreement, to use the Services, and to perform any and all acts
        as may be necessary under these terms and conditions. If the User is
        unable to comply with this requirements they shall be prohibited from
        using the Services and must not accept these terms and conditions.
      </Typography>
      <Typography variant="body1">
        In order to use the Services and to submit or create Content, Users are
        required to create an Account and to submit certain personal details.
      </Typography>
      <Typography variant="body1">
        By accepting these terms and conditions the User represents and warrants
        that:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            any information that is submitted is accurate and truthful;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            all such information will be kept accurate and up-to-date; and
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            the means by which they identify themselves does not violate any
            part of these terms and conditions or any applicable laws.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        If the User has reason to believe that their Account details have been
        obtained by another without consent, the User should contact Mageguide
        P.C. immediately to suspend their Account and cancel any unauthorised
        orders or payments that may be pending. In the event that unauthorised
        use is made prior to the User notifying Mageguide P.C. of the
        unauthorised nature of the order or payment, Mageguide P.C. will suspend
        access to the Services and the withdrawal of any scheduled payments
        pending investigation. Following investigation, it shall be determined
        whether or not to cancel access to the Services and make a full or
        partial refund of the payment to the User.
      </Typography>

      <Typography variant="h2">4. Fees</Typography>
      <Typography variant="body1">
        Fees commence on the date that the User exceeds their limit of free
        usage and inputs their credit or debit card details. The User’s credit /
        debit card will be billed on activation and monthly thereafter.
      </Typography>
      <Typography variant="body1">
        The first payment will be at the price advertised on the Services.
        Mageguide P.C. reserves the right to change Fees from time to time.
      </Typography>
      <Typography variant="body1">
        If a User cancels their Account, access to the Services will terminate
        at the end of the period already paid for. No refunds will be available.
      </Typography>
      <Typography variant="body1">
        If a User’s payment fails to clear or is declined for whatever reason
        then access to the service will be immediately suspended. The service
        will re-commenced once a debit or credit card payment is successfully
        completed.
      </Typography>

      <Typography variant="h2">5. Use of the Services</Typography>
      <Typography variant="body1">
        PriceMonitor is a service for businesses, organizations and individuals
        - users are permitted to use the Services only in accordance with:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            these terms and conditions; and
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            any relevant law, regulation or other applicable instrument in their
            particular jurisdiction.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        Subject to any express agreement to the contrary, Users may only access
        the Services through the normal means. Users shall not attempt to
        download, convert, crawl or otherwise reverse-engineer any part of the
        Services. These restrictions shall not apply to Content submitted or
        created by a particular User where that User is downloading their
        Content, using the service as intended.
      </Typography>
      <Typography variant="body1">
        Users may not engage in any conduct that may disrupt provision of the
        Services.
      </Typography>
      <Typography variant="body1">
        Users may not reproduce, copy, duplicate, trade or resell the Services.
      </Typography>
      <Typography variant="body1">
        Users’ rights to use the Services are non-exclusive, non-transferrable
        and fully revocable at Mageguide P.C.’ discretion.
      </Typography>
      <Typography variant="body1">
        If the User send us any feedback or suggestions regarding the Service,
        they grant Mageguide P.C. an unlimited, irrevocable, perpetual, free
        license to use any such feedback or suggestions for any purpose without
        any obligation to User.
      </Typography>

      <Typography variant="h2">6. Intellectual Property</Typography>
      <Typography variant="body1">
        Subject to the exceptions in Clause 7 of these terms and conditions, all
        Content included on the Website, unless submitted or created by Users,
        including, but not limited to, text, graphics, logos, icons, images,
        sound clips, video clips, data compilations, page layout, underlying
        code and software is the property of Mageguide P.C., our affiliates or
        other relevant third parties. By accepting these terms and conditions
        the User acknowledges that such material is protected by applicable
        Irish and International intellectual property and other laws.
      </Typography>
      <Typography variant="body1">
        Subject to Clause 9 Users may not reproduce, copy, distribute, store or
        in any other fashion re-use material from the Services unless otherwise
        indicated or unless given express written permission to do so by
        Mageguide P.C..
      </Typography>

      <Typography variant="h2">7. Third Party Intellectual Property</Typography>
      <Typography variant="body1">
        Where expressly indicated, certain Content and the Intellectual Property
        Rights subsisting therein belongs to other parties. This Content, unless
        expressly stated to be so, is not covered by any permission granted by
        Clause 6 of these Terms and Conditions to use Content from the Services.
        The exceptions in Clause 9 continue to apply. Any such Content will be
        accompanied by a notice providing the contact details of the owner and
        any separate use policy that may be relevant.
      </Typography>

      <Typography variant="h2">
        8. User Content and Intellectual Property
      </Typography>
      <Typography variant="body1">
        When using the Services to create Content, Users should do so in
        accordance with the following rules:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Users must not submit Content that is unlawful or otherwise
            objectionable. This includes, but is not limited to, Content that is
            abusive, threatening, harassing, defamatory or fraudulent;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Users must not submit Content that is intended to promote or incite
            violence;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Users must not submit Content that may contain viruses or any other
            software or instructions that may damage or disrupt other software,
            computer hardware or communications networks;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Users must not engage in any form of commercial advertising. This
            does not prohibit references to businesses for non-promotional
            purposes including references where advertising may be incidental;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Users must not impersonate other people, particularly employees and
            representatives of Mageguide P.C. or our affiliates;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Users must not use the Services for unauthorised mass-communication
            such as “spam” or “junk mail”.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        Mageguide P.C. has the right, but not the obligation to pre or
        post-screen Content submitted or created by Users and may flag or filter
        any Content that it deems appropriate. If any Content is found to be in
        breach of these terms and conditions, Mageguide P.C. reserves the right
        to remove it without notice and may, at its sole discretion, terminate
        the responsible User’s access to the Services.
      </Typography>
      <Typography variant="body1">
        Users are solely responsible for any and all Content that they submit or
        create. Mageguide P.C. does not endorse, support, represent or otherwise
        guarantee the accuracy or reliability of such Content.
      </Typography>
      <Typography variant="body1">
        Users use the Services at their own risk.
      </Typography>
      <Typography variant="body1">
        By submitting or creating Content, Users warrant and represent that they
        are the author of such Content and / or that they have acquired all of
        the appropriate rights and / or permissions to use the Content in this
        fashion. Mageguide P.C. accepts no responsibility or liability for any
        infringement of third party rights by such Content. Further, Users waive
        all moral rights in any and all Content that they submit or create to be
        named as its author. Mageguide P.C. accepts no responsibility or
        liability for any infringement of third party rights by such Content.
      </Typography>
      <Typography variant="body1">
        By accepting these terms and conditions, the User grants a
        non-exclusive, worldwide, perpetual licence to Mageguide P.C. to copy,
        distribute, transmit, display, transmit and reformat all Content for the
        purpose of providing the Services.
      </Typography>

      <Typography variant="h2">9. Links to Other Websites</Typography>
      <Typography variant="body1">
        The Services may provide links to other websites. Unless expressly
        stated, such websites are not under the control of Mageguide P.C. or
        that of our affiliates. Mageguide P.C. assumes no responsibility for the
        content of the websites and disclaims liability for any and all forms of
        loss or damage arising out of the use of them. The inclusion of a link
        to another website on the Services does not imply any endorsement of
        that website or of those in control of it.
      </Typography>

      <Typography variant="h2">10. Privacy and Data Protection</Typography>
      <Typography variant="body1">
        For the purposes of understanding roles in regard to the General Data
        Protection Regulation (GDPR) (Regulation (EU) 2016/679) - Mageguide P.C.
        is defined as the Data Processor and the Client is the Data Controller.
      </Typography>
      <Typography variant="body1">
        Use of the Services is also governed by{" "}
        <Link underline="none" href={"/privacy"} variant="body1">
          PriceMonitor’s Privacy Policy
        </Link>{" "}
        which is incorporated into these terms and conditions by this reference.
      </Typography>
      <Typography variant="body1">
        The Client, as Data Controller, appoints Mageguide P.C. as a Processor
        to process the Personal Data as described on the Client’s behalf.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will only process the Personal Data to provide the
        Services or otherwise to comply with applicable laws or regulatory
        requirement.
      </Typography>
      <Typography variant="body1">
        Recognising that some fields permit Users to input free form text,
        anything input deemed a special category of data under GDPR will be
        processed pursuant to Article 9.2.b.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will ensure that any person with access to or processing
        the Personal Data is subject to a duty of confidence.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will take appropriate technical and organisational
        security measures to ensure the security of processing and protect the
        Personal Data from accidental or unlawful destruction, loss, alteration,
        unauthorised access or disclosure or unlawful processing.
      </Typography>
      <Typography variant="body1">
        The Client authorises Mageguide P.C. to appoint sub-processors as they
        deem appropriate or necessary for the provision of the Services. New
        sub-processors will be published on the{" "}
        <Link underline="none" href={"/privacy#thirdparties"} variant="body1">
          list of 3rd Party Apps
        </Link>{" "}
        that Mageguide P.C. uses to provide the Service.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will assist the Client in providing subject access and
        allowing data subjects to exercise their rights under the GDPR.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will assist the Client in meeting its GDPR obligations in
        relation to the security of processing, the notification of personal
        data breaches and data protection impact assessments.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will provide mechanisms for the Client to download all
        Personal Data at any time, to delete the record of a single Data
        Subject, and to delete all Personal Data at the end of the contract.
      </Typography>
      <Typography variant="body1">
        The Client may exercise their right of Audit under GDPR legislation
        through Mageguide P.C. providing an audit report not older than 18
        months prepared by an independent external auditor demonstrating
        Mageguide P.C.’ technical and organisational measures are sufficient to
        meet the obligations of a Data Processor under GDPR.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will submit to Client audits and inspections, provided
        the Client pays an applicable audit fee in full, and in advance of the
        commencement of such audit.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. will immediately inform the Client if it is asked to do
        something infringing the GDPR or other data protection law of the EU or
        a member state.
      </Typography>
      <Typography variant="body1">
        Nothing within this contract relieves Mageguide P.C. of its own direct
        responsibilities and liabilities under the GDPR.
      </Typography>

      <Typography variant="h2">11. Disclaimer of Warranties</Typography>
      <Typography variant="body1">
        Mageguide P.C. makes no warranty or representation that the Services
        will meet Users’ requirements, that they will be of satisfactory
        quality, that they will be fit for a particular purpose, that they will
        not infringe the rights of third parties, that they will be compatible
        with all systems, or that they will be secure.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. shall use its best and reasonable endeavours to ensure
        that all information provided on the Services is accurate and up to
        date, however Mageguide P.C. makes no warranty or representation that
        this will always be the case. Mageguide P.C. makes no guarantee of any
        specific results from the use of the Services.
      </Typography>
      <Typography variant="body1">
        No part of the Services is intended to constitute advice and the Content
        of the Services should not be relied upon when making any decisions or
        taking any action of any kind.
      </Typography>
      <Typography variant="body1">
        Whilst every effort has been made to ensure that all descriptions of
        Services available from Mageguide P.C. correspond to the actual services
        available, Mageguide P.C. is not responsible for any variations from
        these descriptions.
      </Typography>

      <Typography variant="h2">12. Availability of the Services</Typography>
      <Typography variant="body1">
        The Services are provided “as is” and on an “as available” basis.
        Mageguide P.C. gives no warranty that the Services will be free of
        defects and / or faults. To the maximum extent permitted by law
        Mageguide P.C. provides no warranties (express or implied) of fitness
        for a particular purpose, accuracy of information, compatibility and
        satisfactory quality.
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. accepts no liability for any disruption or
        non-availability of the Services resulting from external causes
        including, but not limited to, ISP equipment failure, host equipment
        failure, communications network failure, power failure, natural events,
        acts of war, acts of God or legal restrictions and censorship.
      </Typography>

      <Typography variant="h2">13. Limitation of Liability</Typography>
      <Typography variant="body1">
        Mageguide P.C.’ liability for any direct or indirect loss or damage,
        foreseeable or otherwise, including any indirect, consequential, special
        or exemplary damages arising out of our breach of these terms and
        conditions shall be limited to the value of the User’s monthly
        subscription at the relevant time. For all other direct or indirect loss
        or damage, foreseeable or otherwise, including any indirect,
        consequential, special or exemplary damages arising from the use of the
        Services or any information contained therein, to the maximum extent
        permitted by law, Mageguide P.C. accepts no liability. Users should be
        aware that they use the Services and all relevant Content at their own
        risk.
      </Typography>
      <Typography variant="body1">
        Nothing in these terms and conditions excludes or restricts Mageguide
        P.C.’ liability for death or personal injury resulting from any
        negligence or fraud on the part of Mageguide P.C..
      </Typography>

      <Typography variant="h2">14. Term and Termination</Typography>
      <Typography variant="body1">
        The term of the Agreement shall commence upon the User’s acceptance of
        these terms and conditions and shall continue until terminated either by
        the User or by Mageguide P.C. in accordance with this Clause 14.
      </Typography>
      <Typography variant="body1">
        If a User wishes to terminate the Agreement they may do so by Cancelling
        their Account;
      </Typography>
      <Typography variant="body1">
        Mageguide P.C. reserves the right to terminate the Agreement, a User’s
        Account and a User’s access to the Services at any time for the
        following reasons:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            The User has committed a material breach of these terms and
            conditions;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            The User has indicated, expressly or impliedly, that they do not
            intend to or are unable to comply with these terms and conditions;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Mageguide P.C. is required to do so by law;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            It has become, in the opinion of Mageguide P.C., its affiliates or
            advisers, no longer commercially viable to continue providing the
            Services;
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Mageguide P.C. is no longer providing the Services in the User’s
            country of residence; If Mageguide P.C. terminates a User’s Account
            as a result of the User’s breach of these terms and conditions the
            User will not be entitled to any refund.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        If Mageguide P.C. terminates a User’s Account or subscription for any
        other reason, the User will not be refunded any remaining balance of
        their Fees.
      </Typography>
      <Typography variant="body1">
        In the event that Mageguide P.C. terminates a User’s Account or
        subscription, the User will cease to have access to the Services from
        the date of termination.
      </Typography>
      <Typography variant="body1">
        In the event that the Agreement is terminated, the User’s Account will
        be closed and their access to the Services suspended. Any Content that
        the User has submitted or created will become inaccessible to all Users
        from the date on which the Services become inaccessible, however copies
        may be retained by Mageguide P.C. as part of standard backup procedures.
        procedures.
      </Typography>
      <Typography variant="body1">
        Upon termination of the Agreement, the User shall cease to be bound by
        all obligations set out in these terms and conditions with the exception
        of those expressly stated to survive the termination of the Agreement.
      </Typography>

      <Typography variant="h2">15. No Waiver</Typography>
      <Typography variant="body1">
        In the event that either the User or Mageguide P.C. fails to exercise
        any right or remedy contained in these terms and conditions, this shall
        not be construed as a waiver of that right or remedy.
      </Typography>

      <Typography variant="h2">16. Assignment</Typography>
      <Typography variant="body1">
        Users may not assign, transfer, sub-contract, or in any other manner
        make over to any third party the benefit and/or burden of this Agreement
        without the prior written consent of Mageguide P.C., such consent not to
        be unreasonably withheld.
      </Typography>

      <Typography variant="h2">17. Severance</Typography>
      <Typography variant="body1">
        In the event that any of these terms are found to be unlawful, invalid
        or otherwise unenforceable, that term is to be deemed severed from these
        terms and conditions and shall not affect the validity and
        enforceability of the remaining terms and conditions. This term shall
        apply only within jurisdictions where a particular term is illegal.
      </Typography>

      <Typography variant="h2">18. Entire Agreement</Typography>
      <Typography variant="body1">
        These terms and conditions embody and set forth the entire Agreement and
        understanding between the Parties and supersede all prior oral or
        written agreements, understandings or arrangements relating to the
        subject matter of the Agreement. Neither the User nor Mageguide P.C.
        shall be entitled to rely on any agreement, understanding or arrangement
        not expressly set forth in these terms and conditions, save for any
        representation made fraudulently.
      </Typography>

      <Typography variant="h2">19. Notices</Typography>
      <Typography variant="body1">
        All notices / communications shall be sent to and by Mageguide P.C. by
        email to info@mage.guide such notice will be deemed received on the day
        of sending if the email is received in full on a business day and on the
        next business day if the email is sent on a weekend or public holiday.
      </Typography>

      <Typography variant="h2">20. Law and Jurisdiction</Typography>
      <Typography variant="body1">
        These terms and conditions, the Agreement and all other aspects of the
        relationship between the User and Mageguide P.C. shall be governed by
        and construed in accordance with the Laws of Greece. Any dispute between
        the User and Mageguide P.C. relating to these terms and conditions, the
        Agreement and all other aspects of the relationship shall fall within
        the exclusive jurisdiction of the courts of Greece.
      </Typography>
    </Box>
  );
};
