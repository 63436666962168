import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import {
  changeScrapes,
  changeSelectedPlan,
  changeSelectedPlanSlider,
  resetMessage,
  updateMessage,
  useAppContext,
} from "../../../../layout/AppProvider/BaseProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { monthlyDiscounted } from "../../../utils";
import { offers } from "../../../../../helpers/data/pricing";
import { useNavigate } from "react-router-dom";

interface IProps {
  offer: any;
}

export const Option = (props: IProps) => {
  const { state, dispatch } = useAppContext();
  const { language, scrapes, annual, plan } = state;
  const { offer } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const handleChangeScrapes = (event: SelectChangeEvent) => {
    switch (event.target.value as unknown as string) {
      case "1":
        return dispatch(changeScrapes("three"));
      case "2":
        return dispatch(changeScrapes("twelve"));
      default:
        return dispatch(changeScrapes("once"));
    }
  };

  const price = useMemo(() => {
    switch (scrapes) {
      case "three":
        return { value: "1", price: offer.selector[1][scrapes] };
      case "twelve":
        return { value: "2", price: offer.selector[2][scrapes] };
      default:
        return { value: "0", price: offer.selector[0][scrapes] };
    }
  }, [scrapes]);

  const active = useMemo(() => {
    return plan === offer.title.toLowerCase();
  }, [plan]);

  const updatePlan = () => {
    switch (offer.title) {
      case "Starter":
        dispatch(changeSelectedPlan("starter"));
        dispatch(changeSelectedPlanSlider(100));
        return undefined;
      case "Essential":
        dispatch(changeSelectedPlan("essential"));
        dispatch(changeSelectedPlanSlider(500));
        return undefined;
      case "Teams":
        dispatch(changeSelectedPlan("teams"));
        dispatch(changeSelectedPlanSlider(1000));
        return undefined;
      case "Professional":
        dispatch(changeSelectedPlan("professional"));
        dispatch(changeSelectedPlanSlider(5000));
        return undefined;
      case "Enterprise":
        dispatch(changeSelectedPlan("enterprise"));
        dispatch(changeSelectedPlanSlider(10000));
        return undefined;
      default:
        console.warn("Now what its broken!");
        return undefined;
    }
  };

  const handleNext = () => {
    const { language: temp, message, sales, ...rest } = state;
    if (active) {
      dispatch(
        updateMessage(
          `Selected ${rest.annual ? "Yearly" : "Monthly"}, plan: ${
            rest.plan
          }, up to ${rest.products} and scrapes: ${rest.scrapes} per day.`
        )
      );
      navigate("/contact");
      return undefined;
    }
    dispatch(resetMessage());
    return updatePlan();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: active ? "2px solid #3623AD" : "2px solid #E4E7F4",
        borderRadius: "12px",
        padding: "28px 24px 40px",
        background: "white",
        zIndex: 1,
        "&:hover": {
          border: "2px solid #3623AD",
          transition: "border 0.5s",
          cursor: "pointer",
        },
      }}
      onClick={updatePlan}
    >
      <Typography
        variant="h5"
        color="primary.main"
        sx={{ margin: theme.spacing(1, 0) }}
      >
        {offer.title}
      </Typography>
      <Box display="flex" alignItems="flex-end" mb={1}>
        <Typography variant="h1" component={"p"} sx={{ lineHeight: 1 }}>
          {offer.priceEuro(
            annual
              ? Math.floor(monthlyDiscounted(price.price)).toFixed(0)
              : price.price
          )}
        </Typography>
        <Typography variant="h4" component={"p"} sx={{ lineHeight: 1 }}>
          {offer.monthly}
        </Typography>
      </Box>

      <Box sx={{ margin: theme.spacing(2, 0, 3) }}>
        <Typography color="input.dark" variant="body2">
          {offer.case}
        </Typography>
      </Box>
      <Box>
        <Select
          id="scrape-interval-select"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            "& .MuiSelect-icon": {
              color: theme.palette.primary.dark,
            },
            [theme.breakpoints.between(1280, 1440)]: {
              fontSize: theme.spacing(1.75),
            },
          }}
          disabled={offer.title === "Starter"}
          IconComponent={ExpandMoreIcon}
          value={price.value}
          onChange={handleChangeScrapes}
        >
          {offer.selector.map((interval: { option: string }, ind: number) => (
            <MenuItem key={`scrape-interval-${ind}`} value={`${ind}`}>
              {interval.option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ width: "100%", marginTop: theme.spacing(2.5) }}>
        <Button
          color="secondary"
          fullWidth
          variant={active ? "contained" : "outlined"}
          onClick={handleNext}
        >
          {offers[language].button}
        </Button>
      </Box>
    </Box>
  );
};
