import React, { PropsWithChildren } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  SvgIcon,
} from "@mui/material";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { features } from "../../../../../helpers/data/pricing";
import { FeatureCheckIcon } from "../../../../../entities/iconHelper";
import {
  blobOne,
  blobthree,
  blobTwo,
  squares,
} from "../../../../../entities/imageHelper";
import Swiper from "react-id-swiper";

export const Features = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: desktop ? "100%" : "calc(100% - 32px)",
      }}
    >
      <Typography
        sx={{
          fontSize: theme.spacing(4),
          lineHeight: theme.spacing(5),
          margin: theme.spacing(10, 0, 15),
          [theme.breakpoints.down("md")]: {
            fontSize: theme.spacing(3),
            margin: theme.spacing(3, 0, 5),
          },
        }}
      >
        {features[language].title}
      </Typography>
      <Box
        display="flex"
        sx={{
          width: desktop ? theme.spacing(150) : "100%",
          justifyContent: "space-between",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            justifyContent: "space-around",
          },
        }}
      >
        {desktop ? (
          <>
            <Box
              sx={{
                position: "absolute",
                height: theme.spacing(12),
                width: theme.spacing(12),
                zIndex: -1,
                top: "40px",
                left: "-72px",
              }}
            >
              <img src={squares} style={{ width: "100%", height: "100%" }} />
            </Box>
            <Paper
              elevation={1}
              sx={{
                padding: theme.spacing(4, 4, 0, 4),
                "& >div:not(last-child)": { marginBottom: theme.spacing(4) },
              }}
            >
              {features[language].block1.map((feature, ind) => (
                <Box
                  key={`block${ind}-feature${ind}`}
                  sx={{
                    width: theme.spacing(40),
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FeatureCheckIcon />
                  <Typography
                    sx={{ marginLeft: theme.spacing(1) }}
                    variant="h5"
                  >
                    {feature.title}
                  </Typography>
                </Box>
              ))}
            </Paper>
            <Box
              sx={{
                position: "absolute",
                height: theme.spacing(60),
                width: theme.spacing(60),
                zIndex: -1,
                top: "-40px",
                left: "40px",
                transform: "rotate(30deg)",
              }}
            >
              <img src={blobOne} style={{ width: "100%", height: "100%" }} />
            </Box>
            <Paper
              elevation={1}
              sx={{
                padding: theme.spacing(4, 4, 0, 4),
                "& >*:not(last-child)": { marginBottom: theme.spacing(4) },
              }}
            >
              {features[language].block2.map((feature, ind) => (
                <Box
                  key={`block${ind + 4}-feature${ind + 4}`}
                  sx={{
                    width: theme.spacing(40),
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FeatureCheckIcon />
                  <Typography
                    sx={{ marginLeft: theme.spacing(1) }}
                    variant="h5"
                  >
                    {feature.title}
                  </Typography>
                </Box>
              ))}
            </Paper>
            <Box
              sx={{
                position: "absolute",
                height: theme.spacing(50),
                width: theme.spacing(50),
                zIndex: -1,
                top: "-40px",
                right: "40px",
              }}
            >
              <img src={blobTwo} style={{ width: "100%", height: "100%" }} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                height: theme.spacing(10),
                width: theme.spacing(10),
                zIndex: -1,
                bottom: "-120px",
                right: "400px",
              }}
            >
              <img src={blobthree} style={{ width: "100%", height: "100%" }} />
            </Box>
            <Paper
              elevation={1}
              sx={{
                padding: theme.spacing(4, 4, 0, 4),
                "& >*:not(last-child)": { marginBottom: theme.spacing(4) },
              }}
            >
              {features[language].block3.map((feature, ind) => (
                <Box
                  key={`block${ind + 8}-feature${ind + 8}`}
                  sx={{
                    width: theme.spacing(40),
                    display: "flex",
                    alignItems: "center",
                    marginBottom: theme.spacing(4),
                  }}
                >
                  <FeatureCheckIcon />
                  <Typography
                    sx={{ marginLeft: theme.spacing(1) }}
                    variant="h5"
                  >
                    {feature.title}
                  </Typography>
                </Box>
              ))}
            </Paper>
          </>
        ) : (
          <>
            <Swiper
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={16}
            >
              <Paper
                elevation={1}
                sx={{
                  padding: theme.spacing(4, 4, 0, 4),
                  width: theme.spacing(30) + "!important",
                  "& >div:not(:last-child)": { marginBottom: theme.spacing(4) },
                  [theme.breakpoints.down("md")]: {
                    padding: theme.spacing(3, 3, 0, 3),
                    "& >div:not(:last-child)": {
                      marginBottom: theme.spacing(3),
                    },
                  },
                }}
              >
                {features[language].mblock1.map((feature, ind) => (
                  <Box
                    key={`mobile-block${ind}-feature${ind}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(4),
                    }}
                  >
                    <FeatureCheckIcon />
                    <Typography
                      sx={{ marginLeft: theme.spacing(1) }}
                      variant="h5"
                    >
                      {feature.title}
                    </Typography>
                  </Box>
                ))}
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  padding: theme.spacing(4, 4, 0, 4),
                  width: theme.spacing(30) + "!important",
                  "& >div:not(last-child)": { marginBottom: theme.spacing(4) },
                  [theme.breakpoints.down("md")]: {
                    padding: theme.spacing(3, 3, 0, 3),
                    "& >div:not(last-child)": {
                      marginBottom: theme.spacing(3),
                    },
                  },
                }}
              >
                {features[language].mblock2.map((feature, ind) => (
                  <Box
                    key={`mobile-block${ind + 6}-feature${ind + 6}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(4),
                    }}
                  >
                    <FeatureCheckIcon />
                    <Typography
                      sx={{ marginLeft: theme.spacing(1) }}
                      variant="h5"
                    >
                      {feature.title}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Swiper>
          </>
        )}
      </Box>
    </Box>
  );
};
