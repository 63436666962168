import { Language } from "../../entities/types";

export const annualOffer = (price: number) => {
  const annual = price * 12;
  const offer = annual * 0.1;
  return { finalPrice: annual - offer, discount: offer, original: annual };
};

export const monthlyDiscounted = (price: number) => {
  const discount = price * 0.1;
  return price - discount;
};

export const capitalizeFirstLetter = (str: string) => {
  const words = str.replace(/_+/g, " ").split(" ");
  return words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};
