import React, { PropsWithChildren } from "react";
import { Box, Typography, useMediaQuery, useTheme, Paper } from "@mui/material";
import { updates } from "../../../../../helpers/data/pricing";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { calendarPricing } from "../../../../../entities/imageHelper";

export const Updates = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        width: "calc(100% - 320px)",
        justifyContent: "space-between",
        mt: theme.spacing(30),
        background: "#F6F6FF",
        padding: theme.spacing(17, 20),
        [theme.breakpoints.down(1440)]: {
          width: "calc(100% - 80px)",
          flexDirection: "column",
          alignItems: "center",
          padding: theme.spacing(17, 5),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(8, 2),
          width: "calc(100% - 32px)",
          flexDirection: "column",
          mt: theme.spacing(8),
        },
      }}
    >
      <Box
        sx={{
          width: theme.spacing(90),
          [theme.breakpoints.down("lg")]: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: theme.spacing(10),
          },
        }}
      >
        <Typography
          sx={{
            fontSize: theme.spacing(4.5),
            fontWeight: 800,
            lineHeight: theme.spacing(5),
            margin: theme.spacing(0, 0, 15),
            [theme.breakpoints.down("sm")]: {
              margin: theme.spacing(0),
            },
            [theme.breakpoints.down("md")]: {
              margin: theme.spacing(0, 0, 8),
              fontSize: theme.spacing(4),
            },
          }}
        >
          {updates[language].title}
        </Typography>
        {desktop && (
          <Box
            sx={{
              width: theme.spacing(67.5),
              height: theme.spacing(46.75),
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={calendarPricing}
            />
          </Box>
        )}
      </Box>
      <Box sx={{}}>
        {updates[language].updates.map((update, ind) => (
          <Paper
            sx={{
              display: "flex",
              width: theme.spacing(80),
              padding: theme.spacing(3, 2.5),
              marginBottom:
                ind < updates[language].updates.length - 1
                  ? theme.spacing(3)
                  : 0,
              marginLeft: theme.spacing(3 * ind),
              [theme.breakpoints.down("md")]: {
                marginLeft: 0,
                width: "calc(100% - 40px)",
              },
            }}
            key={`update-${ind}`}
            elevation={1}
          >
            <Box
              sx={{
                width: theme.spacing(6),
                height: theme.spacing(6),
                marginRight: theme.spacing(3),
                [theme.breakpoints.down("md")]: {
                  marginRight: theme.spacing(2),
                },
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={update.icon}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: theme.spacing(65),
                [theme.breakpoints.down("md")]: {
                  width: "calc(100% - 72px)",
                },
                [theme.breakpoints.down(400)]: {
                  width: theme.spacing(25),
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                  marginBottom: theme.spacing(1),
                }}
              >
                {update.title}
              </Typography>
              <Typography variant="body2">{update.text}</Typography>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};
