import { IPalette } from "./IPalette";

const lightPalette: IPalette = {
  purpleTheme: {
    palette: {
      primary: {
        dark: "#0E0063",
        main: "#3623AD",
        light: "#E3E5FC",
        contrastText: "#FFFFFF",
      },
      secondary: {
        dark: "#B44A73",
        main: "#D43C76",
        light: "#E99DBB",
        contrastText: "#FFFFFF",
      },
      info: {
        dark: "#01579b",
        main: "#0288d1",
        light: "#03a9f4",
        contrastText: "#FFFFFF",
      },
      warning: {
        dark: "#9e5c35",
        main: "#e6854e",
        light: "#FFEFE5",
        contrastText: "#FFFFFF",
      },
      success: {
        dark: "#1e6659",
        main: "#30A590",
        light: "#CBFBEC",
        contrastText: "#FFFFFF",
      },
      error: {
        dark: "#872c27",
        main: "#C23F38",
        light: "#FFEDF4",
        contrastText: "#FFFFFF",
      },
      input: {
        dark: "#B6B7E7",
        main: "#E3E5FC",
        light: "#F9FAFF",
        contrastText: "#FFFFFF",
      },
      gray: "#8E8E8F",
      lime: "#49fc03",
    },
  },
};

export default lightPalette;
