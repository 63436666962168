import darkPalette from "./darkPalette";
import { IPalette, ThemeMode } from "./IPalette";
import lightPalette from "./lightPalette";

const themePalette = (color: keyof IPalette, mode: ThemeMode) => {
  if (mode === "dark") {
    return darkPalette[color];
  }
  return lightPalette[color];
};

export default themePalette;
