import React, { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import BaseLayoutProvider from "./AppProvider/BaseProvider";
import { Outlet } from "react-router-dom";

const BaseContent = (props: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Header />
      <Outlet />
      <Footer />
    </Box>
  );
};

const Base = () => {
  return (
    <BaseLayoutProvider>
      <BaseContent />
    </BaseLayoutProvider>
  );
};

export default React.memo(Base);
