import React, { PropsWithChildren } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
  useMediaQuery,
  useTheme,
  Menu,
  Drawer,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Banner } from "./Banner";
import { useNavigate } from "react-router-dom";
import {
  changeLanguage,
  resetMessage,
  updateSupportMessage,
  useAppContext,
} from "../AppProvider/BaseProvider";
import { Language } from "../../../entities/types";
import { header } from "../../../helpers/data/base";
import {
  backgroundHead,
  backgroundHeadMobile,
  logo,
} from "../../../entities/imageHelper";
import { CancelModalIcon, MenuMobileIcon } from "../../../entities/iconHelper";

export const Header = (props: PropsWithChildren) => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(changeLanguage(event.target.value as Language));
    handleClose();
  };

  const handleTrial = () => {
    dispatch(resetMessage());
    dispatch(
      updateSupportMessage(
        `Θα ήθελα να ενεργοποιήσω την δωρεάν δοκιμή 7 ημέρων`
      )
    );
    navigate("/contact");
    handleClose();
  };

  return (
    <Box sx={{ position: "relative" }}>
      {!mobile && <Banner />}
      {mobile ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: theme.spacing(2, 0),
          }}
        >
          <Box
            sx={{
              margin: theme.spacing(0, 2),
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                width: theme.spacing(5.5),
                height: theme.spacing(5.5),
              }}
              onClick={() => navigate("")}
            >
              <img style={{ width: "100%", height: "100%" }} src={logo} />
            </Box>
            <Box>
              <IconButton onClick={handleClick}>
                <MenuMobileIcon />
              </IconButton>
            </Box>
            <Drawer
              anchor="right"
              open={open}
              PaperProps={{
                sx: {
                  width: "calc(100% - 32px)",
                  padding: theme.spacing(2),
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: theme.spacing(5),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "pointer",
                    width: theme.spacing(5.5),
                    height: theme.spacing(5.5),
                  }}
                  onClick={() => {
                    navigate("");
                    handleClose();
                  }}
                >
                  <img style={{ width: "100%", height: "100%" }} src={logo} />
                </Box>
                <Box>
                  <IconButton onClick={handleClose}>
                    <CancelModalIcon />
                  </IconButton>
                </Box>
              </Box>
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: theme.palette.input.light,
                  },
                  padding: theme.spacing(3, 0),
                }}
                onClick={() => {
                  navigate("");
                  handleClose();
                }}
                variant="h4"
                component="a"
              >
                {header[state.language].features}
              </Typography>
              <Divider />
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: theme.palette.input.light,
                  },
                  padding: theme.spacing(3, 0),
                }}
                onClick={() => {
                  navigate("/pricing");
                  handleClose();
                }}
                variant="h4"
                component="a"
              >
                {header[state.language].pricing}
              </Typography>
              <Divider />
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: theme.palette.input.light,
                  },
                  padding: theme.spacing(3, 0),
                }}
                onClick={() => {
                  navigate("/contact");
                  handleClose();
                }}
                variant="h4"
                component="a"
              >
                {header[state.language].contact}
              </Typography>
              <Divider />
              <Box>
                <Select
                  id="language-select"
                  variant="standard"
                  fullWidth
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: 700,
                    textTransform: "uppercase",
                    padding: theme.spacing(3, 0),

                    "&:hover": {
                      cursor: "pointer",
                      background: theme.palette.input.light,
                    },
                    "& >div": {
                      padding: 0,
                    },
                    "& .MuiSelect-icon": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                  disableUnderline
                  value={state.language}
                  onChange={handleChange}
                >
                  {header[state.language].languages.map((lang) => (
                    <MenuItem
                      key={`lang-${lang}`}
                      value={lang}
                      sx={{ textTransform: "uppercase" }}
                    >
                      {lang}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ margin: theme.spacing(5, 0, 0) }}
                onClick={() => handleTrial()}
              >
                {header[state.language].trial}
              </Button>
            </Drawer>
          </Box>
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              top: "-32px",
              overflow: "hidden",
              height: "1000px",
              width: "100%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "inherit",
              }}
              src={backgroundHeadMobile}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: theme.spacing(2, 5),
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
                width: theme.spacing(8),
                height: theme.spacing(8),
              }}
              onClick={() => navigate("")}
            >
              <img style={{ width: "100%", height: "100%" }} src={logo} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                "& >*": {
                  margin: theme.spacing(0, 1),
                },
              }}
            >
              <Typography
                sx={{
                  "&:hover": { cursor: "pointer" },
                  margin: theme.spacing(0, 1),
                }}
                onClick={() => navigate("")}
                variant="h4"
                component="a"
              >
                {header[state.language].features}
              </Typography>
              <Typography
                sx={{
                  "&:hover": { cursor: "pointer" },
                  margin: theme.spacing(0, 1),
                }}
                onClick={() => navigate("/pricing")}
                variant="h4"
                component="a"
              >
                {header[state.language].pricing}
              </Typography>
              <Typography
                sx={{
                  "&:hover": { cursor: "pointer" },
                  margin: theme.spacing(0, 1),
                }}
                onClick={() => navigate("/contact")}
                variant="h4"
                component="a"
              >
                {header[state.language].contact}
              </Typography>
              <Box
                sx={{
                  width: "2px",
                  height: "20px",
                  margin: theme.spacing(0, 2),
                  background: theme.palette.input.dark,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleTrial()}
              >
                {header[state.language].trial}
              </Button>
              <Select
                id="language-select"
                variant="standard"
                IconComponent={ExpandMoreIcon}
                sx={{
                  color: theme.palette.primary.dark,
                  margin: theme.spacing(0, 2),
                  fontWeight: 700,
                  textTransform: "uppercase",
                  "& >div": {
                    padding: 0,
                  },
                  "& .MuiSelect-icon": {
                    color: theme.palette.primary.dark,
                  },
                }}
                disableUnderline
                value={state.language}
                onChange={handleChange}
              >
                {header[state.language].languages.map((lang) => (
                  <MenuItem
                    key={`lang-${lang}`}
                    value={lang}
                    sx={{ textTransform: "uppercase" }}
                  >
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box
            sx={{
              zIndex: -1,
              position: "absolute",
              top: "-24px",
              overflow: "hidden",
              height: "1000px",
              width: "100%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "inherit",
              }}
              src={backgroundHead}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
