import React, { PropsWithChildren } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { ColorLabel } from "./ColorLabel";
import { Accordions } from "./Accordions";
import { productsHome, squares } from "../../../../../entities/imageHelper";

export const Products = (props: PropsWithChildren) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down(1780)]: {
          paddingLeft: theme.spacing(5),
          paddingRight: theme.spacing(5),
        },
        [theme.breakpoints.down(1280)]: {
          flexDirection: "column",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(8),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }}
      id="products"
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            margin: theme.spacing(1.5, 0),
          }}
        >
          <Box>
            <ColorLabel color="blue" />
          </Box>
          <Box
            sx={{
              width: theme.spacing(5),
              height: theme.spacing(5),
              marginLeft: theme.spacing(40),
              [theme.breakpoints.down("md")]: {
                marginLeft: "auto",
                display: "flex",
              },
            }}
          >
            <img style={{ width: "100%", height: "100%" }} src={squares} />
          </Box>
        </Box>
        <Accordions case="products" />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down(1360)]: {
            marginTop: theme.spacing(-5),
          },
          [theme.breakpoints.down(1280)]: {
            minWidth: theme.spacing(50),
          },
          [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(0),
            minWidth: "100% !important",
          },
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: theme.spacing(150),
            height: "auto",
          }}
          src={productsHome}
        />
      </Box>
    </Box>
  );
};
