import React, { PropsWithChildren } from "react";
import {
  Box,
  Paper,
  SvgIcon,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IProps {
  feature: {
    icon: string;
    title: string;
    text: string;
  };
}

export const FeatureCard = (props: IProps) => {
  const { feature } = props;
  const theme = useTheme();

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "calc(100% - 48px)",
        height: "calc(100% - 64px)",
        padding: theme.spacing(4, 3),
      }}
    >
      <Box sx={{ width: theme.spacing(11), height: theme.spacing(11) }}>
        <img style={{ width: "100%", height: "100%" }} src={feature.icon} />
      </Box>
      <Typography
        sx={{ margin: theme.spacing(1.5, 0), lineHeight: "20px" }}
        variant="h5"
      >
        {feature.title}
      </Typography>
      <Typography sx={{ fontWeight: 400, lineHeight: "22px" }} variant="body1">
        {feature.text}
      </Typography>
    </Paper>
  );
};
