import React, { PropsWithChildren, useCallback } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { products, competitors } from "../../../../../helpers/data/home";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";

interface IProps {
  case: "products" | "competitors";
}

export const Accordions = (props: IProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(`panel0`);
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();

  const values = useCallback(() => {
    if (props.case === "products") return products[language];
    return competitors[language];
  }, [language]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        width: theme.spacing(55),
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      }}
    >
      <Typography
        sx={{
          margin: theme.spacing(1.5, 0),
          fontWeight: 800,
          fontSize: theme.spacing(6),
          lineHeight: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: theme.spacing(4),
          },
        }}
      >
        {values().title}
      </Typography>
      <Box sx={{ margin: theme.spacing(3, 0) }}>
        {values().accordions.map((accordion, ind) => (
          <Accordion
            key={`panel${ind}-${props.case}`}
            expanded={expanded === `panel${ind}`}
            onChange={handleChange(`panel${ind}`)}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${ind}bh-content`}
              id={`panel${ind}bh-header`}
            >
              <Typography component={"p"} variant="h4">
                {accordion.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ fontWeight: 400, lineHeight: "22px" }}
                variant="body1"
              >
                {accordion.text}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};
