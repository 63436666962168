import React from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    page: "privacy",
  },
  dataLayerName: "PageDataLayer",
};

export const Privacy = () => {
  TagManager.dataLayer(tagManagerArgs);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        margin: theme.spacing(10, "auto", 35),
        [theme.breakpoints.down(1440)]: {
          width: "60%",
        },
        [theme.breakpoints.down(1280)]: {
          width: "70%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "90%",
        },
        "& p": {
          lineHeight: "1.5rem",
          fontSize: "1rem",
          margin: theme.spacing(2, 0, 0),
        },
        "& h2": {
          margin: theme.spacing(3, 0, 0),
          lineHeight: 1.2,
          fontSize: "2rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "1.5rem",
          },
        },
        "& h3": {
          margin: theme.spacing(1.5, 0, 1.25),
          lineHeight: 1.2,
          fontSize: "1rem",
        },
        "& a": {
          color: "#8774F6",
          lineHeight: "1.5rem",
          fontSize: "1rem",
        },
        "& h1": {
          lineHeight: 1.2,
          fontSize: "2.5rem",
          [theme.breakpoints.down("md")]: {
            fontSize: "2.25rem",
          },
        },
        "& ul": {
          margin: 0,
        },
      }}
    >
      <Typography id="privacy" variant="h1">
        Privacy Policy
      </Typography>
      <Typography variant="body1">
        We know your personal information is important to you and it is also
        important to us. This Privacy Policy tells you what we use your personal
        information for and explains your rights around how we use it. Please
        read this Privacy Policy to understand how and why we use your personal
        information.
      </Typography>
      <Typography variant="body1">
        If you give us personal information about someone else, please make sure
        you have their permission and please make them aware of this Privacy
        Statement as it also applies to them.
      </Typography>
      <Typography variant="body1">
        The definitions provided in our{" "}
        <Link underline="none" href={"/tos"} variant="body1">
          Terms of Service
        </Link>{" "}
        apply to this Privacy Policy as well.
      </Typography>

      <Typography id="collect" variant="h2">
        What we collect
      </Typography>
      <Typography variant="body1">
        We ask for your name and email address when you signup to our service.
        This is our primary mechanism to identify you when you log in.
      </Typography>
      <Typography variant="body1">
        For the purposes of providing you with a compliant receipt for your Fees
        payments, we may also collect your address.
      </Typography>

      <Typography id="store" variant="h2">
        How we store the information
      </Typography>
      <Typography variant="body1">
        We take great care to keep your personal information safe, starting with
        our cloud infrastructure provider which is{" "}
        <Link
          underline="none"
          href={"https://aws.amazon.com/"}
          variant="body1"
          target="_blank"
        >
          Amazon Web Services
        </Link>
        . Their computers that are housed in secure data centres with heavily
        restricted access with numerous levels of security to prevent
        unauthorised access to those servers including firewalls and passwords.
      </Typography>
      <Typography variant="body1">
        Amazon provide comprehensive details on their{" "}
        <Link
          underline="none"
          href={"https://aws.amazon.com/security/"}
          variant="body1"
          target="_blank"
        >
          Security
        </Link>{" "}
        and{" "}
        <Link
          underline="none"
          href={"https://aws.amazon.com/privacy/"}
          variant="body1"
          target="_blank"
        >
          Privacy
        </Link>
        .
      </Typography>

      <Typography id="use" variant="h2">
        What we do with the information
      </Typography>
      <Typography variant="body1">
        Email is a vital part of PriceMonitor, emails are used to notify users
        about failing tests and other important events. We also use email to
        send occasional alerts, reminders and newsletters in relation to
        PriceMonitor and how you, all these emails contain a link that allows a
        user to opt out of further communication with a single click.
      </Typography>
      <Typography variant="body1">
        The primary use of the data we collect is to provide the service of
        PriceMonitor.
      </Typography>

      <Typography id="cookies" variant="h2">
        Cookies
      </Typography>
      <Typography variant="body1">
        In order to offer you a convenient online service featuring numerous
        functions, our Services uses text files (“Cookies”) containing
        information to identify returning visitors for the time of their visit
        to the Services. Cookies facilitate the transfer of specific content,
        such as entering data, which has already been supplied, and help us
        identify popular sections of our Services. OPT-OUT: You can deactivate
        the use of Cookies in the settings of your internet browser at any time.
        To find out how to change the settings, please consult the help function
        of your internet browser.
      </Typography>

      <Typography id="thirdparties" variant="h2">
        Who we share the information with
      </Typography>
      <Typography variant="body1">
        To help us deliver PriceMonitor we use various 3rd party apps. We asses
        all these apps to ensure our continued compliance with our obligations
        to Users, Clients, and data processing regulations such as GDPR, and to
        ensure your data remains in safe hands.
      </Typography>
      <Typography variant="body1">
        We list those apps and 3rd party data processors here:
      </Typography>
      <ul>
        <li>
          <Typography variant="h3">Google Inc.</Typography>
          <Typography variant="body1">
            We use Google Analytics to help us understand the way people use
            PriceMonitor so we can make it better and communicate relevant
            information to users. To provide this Google collects anonymised
            statistical data about the use of our website and applications.
            <Link
              underline="none"
              href={"https://support.google.com/analytics/answer/6004245?hl=en"}
              variant="body1"
              target="_blank"
            >
              Read Google Analytics’ Privacy Policy
            </Link>
            . We also use Google Cloud for email, documents, spreadsheets and
            other collaboration tools.{" "}
            <Link
              underline="none"
              href={
                "https://support.google.com/googlecloud/answer/6056650?hl=en"
              }
              variant="body1"
              target="_blank"
            >
              Read Google Cloud’s Privacy statement
            </Link>
            .
          </Typography>
        </li>
        <li>
          <Typography variant="h3">Slack Inc.</Typography>
          <Typography variant="body1">
            We primarily use Slack for internal electronic communications. It’s
            entirely possible that at times these discussions will be about
            clients and particular situations they need assistance with, and in
            that regard data will be shared. Slack store their Data in z`zUSA
            Data Centres and have certified with{" "}
            <Link
              underline="none"
              href={"https://slack.com/privacy-shield-notice"}
              variant="body1"
              target="_blank"
            >
              EU-US Privacy Shield
            </Link>
            . Our relationship is governed by their{" "}
            <Link
              underline="none"
              href={"https://slack.com/terms-of-service"}
              variant="body1"
              target="_blank"
            >
              Terms of Service
            </Link>{" "}
            and a GDPR compliant Data Processing Addendum.
          </Typography>
        </li>
        <li>
          <Typography variant="h3">LogDNA, Inc.</Typography>
          <Typography variant="body1">
            We use LogDNA to aggregate our server logs.{" "}
            <Link
              underline="none"
              href={"https://logdna.com/gdpr/"}
              variant="body1"
              target="_blank"
            >
              Read LogDNA’s GDPR Policy
            </Link>
            .
          </Typography>
        </li>
        <li>
          <Typography variant="h3">Stripe Inc.</Typography>
          <Typography variant="body1">
            We process debit and credit card payments using Stripe Payments
            Europe Limited, a worldwide payments provider. The main capture is
            through their european subsidiary based in Ireland, but some of the
            data is passed to Stripe Inc. the parent company in the USA. For
            this transfer to be lawful they employ the{" "}
            <Link
              underline="none"
              href={
                "https://support.stripe.com/questions/stripe-and-european-data-transfers"
              }
              variant="body1"
              target="_blank"
            >
              European Commission’s Standard Contractual Clauses (“Model
              Clauses”)
            </Link>{" "}
            to allow for the lawful transfer of such data under the EU Data
            Directive.
          </Typography>
        </li>
      </ul>

      <Typography id="retention" variant="h2">
        Retention and Destruction
      </Typography>
      <Typography variant="body1">
        Our policy is to provide our customers with control over retention and
        destruction of their data. Individual users of PriceMonitor can be
        deleted anytime as well as entire customer accounts. Deletion is instant
        and irrecoverable. Any data stored on backups will be deleted entirely
        within 30 days.
      </Typography>

      <Typography id="pledge" variant="h2">
        What we don’t and won’t ever do
      </Typography>
      <Typography variant="body1">
        We don’t, and never will sell or rent your information to any 3rd party.
      </Typography>

      <Typography id="faq" variant="h2">
        GDPR FAQs
      </Typography>
      <Typography variant="body1">
        A number of common questions have arisen in regard to GDPR which we’ll
        try to answer here:
      </Typography>

      <Typography variant="h3">Where is the data stored?</Typography>
      <Typography variant="body1">
        Data is stored in the Amazon Web Services cloud platform. At the time of
        writing this is their sites of North Virginia, US.
      </Typography>

      <Typography variant="h3">Is Data encrypted?</Typography>
      <Typography variant="body1">
        Data is transferred to PriceMonitor using HTTPS and encrypted at rest.
      </Typography>

      <Typography variant="h3">How do we delete data?</Typography>
      <Typography variant="body1">
        You can delete individual users and all their data from PriceMonitor
        within the app itself. You can also cancel and delete your entire
        account by contacting our support team.
      </Typography>

      <Typography variant="h3">Which sub processors do you use?</Typography>
      <Typography variant="body1">
        This is covered in more depth in the list of 3rd Party Apps we use here.
      </Typography>

      <Typography variant="h3">
        Do you have a specific data processing agreement?
      </Typography>
      <Typography variant="body1">
        We don’t have a separate data processing agreement, the specific terms
        required under Article 28 of the GDPR Act are included in the main Terms
        and Conditions.
      </Typography>

      <Typography variant="h3">Who is your Data Processing Officer?</Typography>
      <Typography variant="body1">
        PriceMonitor isn’t required to have a GDPR Officer. GDPR only requires
        an officer if the organisation is a public body, conducts large scale
        systematic monitoring of individuals such as behaviour tracking, or
        tracks special categories of data such as health.
      </Typography>

      <Typography variant="h3">
        Can you sign our GDPR / Data Processing Agreement?
      </Typography>
      <Typography variant="body1">
        Unfortunately we can’t sign agreements with individual clients, sorry.
        Being a small dynamic company, it would be pretty much impossible for us
        to manage separate legal agreements with each client - hence why you’ll
        find all the GDPR requirements taken care of in our standard Terms and
        Conditions.
      </Typography>

      <Typography id="gdpr" variant="h2">
        GDPR: Subject Access Requests
      </Typography>
      <Typography variant="body1">
        We recognise our obligations in assisting customers with regard to data
        subject access requests.
      </Typography>
      <Typography variant="body1">
        If you are a Data Subject, Subject Access requests should be sent direct
        to your employer (the data controller), who in turn will seek assistance
        from us, Mageguide P.C. (as data processor) in complying with that
        request. So please contact your employer directly.
      </Typography>
      <Typography variant="body1">
        If you represent an organisation using PriceMonitor, access to your data
        is provided via the web and mobile applications. Both you and your users
        can login and see relevant user data.
      </Typography>
      <Typography variant="body1">
        The information you find from logging into PriceMonitor reports should
        be sufficient to allow you to complete the subject access request, but
        if you have any concerns then please contact support for further
        assistance.
      </Typography>

      <Typography id="updates" variant="h2">
        Updates to this policy
      </Typography>
      <Typography variant="body1">
        We update this privacy policy from time-to-time, particularly as
        technology changes and as PriceMonitor develops. We suggest you check
        back from time to time for the latest version.
      </Typography>
    </Box>
  );
};
