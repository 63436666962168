import React, { PropsWithChildren } from "react";
import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ReCAPTCHA from "react-google-recaptcha";
import { logoWhite } from "../../../entities/imageHelper";
import { footer } from "../../../helpers/data/base";
import { useAppContext } from "../AppProvider/BaseProvider";
import { useNavigate } from "react-router-dom";

export const Footer = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const [emailValue, setEmailValue] = React.useState<string>("");
  const [notEmail, setNotEmail] = React.useState<boolean>();
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const navigate = useNavigate();
  const { language } = state;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i
    setNotEmail(!regex.test(emailValue));
    setEmailValue(e.target.value as string)
    recaptchaRef.current?.execute();
  }

  const handleSubscribe = () => {
    const recaptchaValue = recaptchaRef.current?.getValue();
    emailjs
          .send(
          "service_pm_info",
          "subscribe_template",
          {
            to_email: emailValue,
            "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE",
          )
          .then(
            async () => {
              console.log(`subscribed ${emailValue}`);
            },
            () => {
              // console.log("FAILED...", error);
            }
        );
    return setEmailValue("");
  }

  const disable = React.useMemo(() => {
    if (!emailValue || emailValue.length<1){
      return false;
    }
    if (emailValue && notEmail){
      return true;
    }
    return false;
  },[notEmail, emailValue])

  return (
    <Box
      sx={{
        background: theme.palette.primary.dark,
        padding: theme.spacing(10, 25, 5),
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(5, 2),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            "& h5": {
              margin: theme.spacing(2, 0, 0.5),
            },
          },
        }}
      >
        {mobile && (
          <Box display="flex" flexDirection="column">
            <Typography
              sx={{ textAlign: "center" }}
              color="primary.light"
              variant="h5"
            >
              Join our newsletter
            </Typography>
            <Box
              sx={{
                background: theme.palette.primary.dark,
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter E-mail"
                fullWidth
                sx={{
                  "& >div": {
                    background: theme.palette.input.light,
                    borderRadius: theme.spacing(4),
                  },
                }}
                helperText={notEmail? footer[language].newsLetterError.value : null}
                error={disable}
                value={emailValue}
                onChange={handleEmailChange}
                InputProps={{
                  endAdornment: (
                    <Button 
                        variant="contained" 
                        onClick={handleSubscribe} 
                        color="primary"
                        disabled={disable}
                      >
                      Subsrcibe
                    </Button>
                  ),
                }}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcmkkMiAAAAAPMekQawPxE8ygie8RzxJ2rr7XCO"
                size="invisible"
              />
            </Box>
          </Box>
        )}
        {!mobile && (
          <Box display="flex" flexDirection="column" maxWidth="240px">
            <Box
              sx={{
                width: theme.spacing(10),
                height: theme.spacing(10),
                marginBottom: theme.spacing(1),
              }}
              onClick={() => navigate("")}
            >
              <img style={{ width: "100%", height: "100%" }} alt="@" src={logoWhite} />
            </Box>
            <Typography sx={{ color: "#A4ACCE" }} variant="body1">
              {footer[language].description}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" marginTop={3}>
          <Link
            href={footer[language].features.link}
            component="h5"
            underline="none"
            color="primary.light"
            variant="h5"
          >
            {footer[language].features.title}
          </Link>
          <Link
            underline="none"
            href={footer[language].keyFeatures.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].keyFeatures.title}
          </Link>
          <Link
            underline="none"
            href={footer[language].product.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].product.title}
          </Link>
          <Link
            underline="none"
            href={footer[language].competitors.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].competitors.title}
          </Link>
          <Link
            underline="none"
            href={footer[language].experience.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].experience.title}
          </Link>
        </Box>
        <Box display="flex" flexDirection="column" marginTop={3}>
          <Link
            underline="none"
            href={footer[language].pricing.link}
            component="h5"
            color="primary.light"
            variant="h5"
          >
            {footer[language].pricing.title}
          </Link>
          <Typography color="primary.light" variant="h5">
            {footer[language].legal.title}
          </Typography>
          <Link
            underline="none"
            href={footer[language].legal.privacyPolicy.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].legal.privacyPolicy.title}
          </Link>
          <Link
            underline="none"
            href={footer[language].legal.termsAndConditions.link}
            color="primary.light"
            variant="body2"
          >
            {footer[language].legal.termsAndConditions.title}
          </Link>
        </Box>
        <Box display="flex" flexDirection="column" marginTop={3}>
          <Typography color="primary.light" variant="h5">
            Contact
          </Typography>
          <Link
            variant="body2"
            target="_blank"
            underline="none"
            color="primary.light"
            href="mailto&#58;&#105;nfo&#64;pr&#105;%6&#51;&#101;&#37;6D&#111;ni&#116;o%72&#46;%61%6&#57;"
          >
            i&#110;fo&#64;pr&#105;c&#101;m&#111;nit&#111;r&#46;a&#105;
          </Link>
          <Link
            href="https://maps.google.com/maps?q=Arkadias+3,+Μενεμένη+115+26"
            variant="body2"
            underline="none"
            target="_blank"
            color="primary.light"
          >
            Arkadias 3 Ampelokipoi, Athens
          </Link>
          <Link
            variant="body2"
            target="_blank"
            underline="none"
            color="primary.light"
            href="tel:+302111989293"
          >
            (+30)211 1989 293
          </Link>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
                margin: theme.spacing(2, 0, 0),
              },
            }}
          >
            <Link
              sx={{
                ">*": { color: "white" },
              }}
              underline="none"
              href={"https://www.linkedin.com/company/pricemonitor/"}
              target="_blank"
            >
              <LinkedInIcon />
            </Link>
          </Box>
        </Box>
        {!mobile && (
          <Box display="flex" flexDirection="column" marginTop={3}>
            <Typography color="primary.light" variant="h5" marginBottom={1.25}>
              Join our newsletter
            </Typography>
            <Box
              sx={{
                background: theme.palette.primary.dark,
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter E-mail"
                type="email"
                error={disable}
                value={emailValue}
                helperText={notEmail? footer[language].newsLetterError.value : null}
                sx={{
                  "& >div": {
                    background: theme.palette.input.light,
                    borderRadius: theme.spacing(4),
                  },
                }}
                onChange={handleEmailChange}
                InputProps={{
                  endAdornment: (
                    <Button disabled={disable} onClick={handleSubscribe} variant="contained" color="primary">
                      Subsrcibe
                    </Button>
                  ),
                }}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcmkkMiAAAAAPMekQawPxE8ygie8RzxJ2rr7XCO"
                size="invisible"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          background: theme.palette.primary.light,
          margin: theme.spacing(3, 0),
        }}
      />
      <Box display="flex" justifyContent="center">
        <Typography variant="body2" color="primary.light">
          © 2022 Pricemonitor.ai
        </Typography>
      </Box>
    </Box>
  );
};
