import React, { PropsWithChildren } from "react";
import {
  Box,
  Link,
  Button,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { info } from "../../../../../helpers/data/contact";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  AddressIcon,
  CalendarIcon,
  PhoneCallIcon,
} from "../../../../../entities/iconHelper";
import { squares } from "../../../../../entities/imageHelper";
import { Info } from "./Info";

export const Booking = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const theme = useTheme();
  const { language } = state;
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        width: theme.spacing(80),
        flexDirection: "column",
        [theme.breakpoints.down(1440)]: {
          width: "auto",
          marginRight: theme.spacing(5),
        },
        [theme.breakpoints.down("md")]: {
          width: "100%",
          marginBottom: theme.spacing(7),
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "inherit" }}>
        <Box
          sx={{
            width: theme.spacing(8),
            height: theme.spacing(8),
            marginLeft: theme.spacing(50),
            [theme.breakpoints.down("md")]: {
              marginLeft: "auto",
            },
          }}
        >
          <img style={{ width: "100%", height: "100%" }} src={squares} />
        </Box>
        <Typography
          sx={{
            margin: theme.spacing(1.5, 0),
            fontWeight: 800,
            fontSize: theme.spacing(6),
            lineHeight: 1,
            [theme.breakpoints.down("md")]: {
              fontSize: theme.spacing(4),
            },
          }}
        >
          {info[language].title}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.spacing(2.25),
            lineHeight: 1.5,
            margin: theme.spacing(2, 0),
          }}
          variant="body1"
        >
          {info[language].text}
        </Typography>
        <Box
          sx={{ display: "flex", width: { xs: "100%", sm: theme.spacing(30) } }}
        >
          <Button
            variant="contained"
            color="secondary"
            href={`https://calendly.com/pricemonitor/30min`}
            target="_blank"
            fullWidth
            sx={{
              padding: theme.spacing(1.5, 0),
              margin: theme.spacing(2, 0),
            }}
          >
            <CalendarIcon />
            <Typography
              variant="h6"
              sx={{ margin: theme.spacing(0, 1) }}
              color="primary.contrastText"
            >
              {info[language].button}
            </Typography>
            <ChevronRightIcon
              fontSize="small"
              sx={{
                color: theme.palette.primary.contrastText,
                margin: theme.spacing(0, 1),
              }}
            />
          </Button>
        </Box>
      </Box>
      {!mobile && <Info />}
    </Box>
  );
};
