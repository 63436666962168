import React, { PropsWithChildren } from "react";
import { Typography, Box, Button, Link } from "@mui/material";
import { useAppContext } from "../AppProvider/BaseProvider";
import { header } from "../../../helpers/data/base";

export const Banner = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        position: "sticky",
        alignItems: "center",
        justifyContent: "center",
        background: (theme) => theme.palette.input.main,
        padding: (theme) => theme.spacing(0.75, 0),
      }}
    >
      <Typography component={"p"} variant="h4">
        {header[language].banner.contact}:{" "}
        <Link color="primary.main" underline="none" href="tel:+302111989293">
          {header[language].banner.phone}
        </Link>{" "}
        &{" "}
        <Link
          color="primary.main"
          underline="none"
          href="mailto&#58;&#105;nfo&#64;pr&#105;%6&#51;&#101;&#37;6D&#111;ni&#116;o%72&#46;%61%6&#57;"
        >
          i&#110;fo&#64;pr&#105;c&#101;m&#111;nit&#111;r&#46;a&#105;
        </Link>{" "}
        {header[language].banner.timeslots}
      </Typography>
    </Box>
  );
};
