import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useAppContext,
  updateMessage,
} from "../../../../layout/AppProvider/BaseProvider";
import { offers } from "../../../../../helpers/data/pricing";
import {
  arrowCustom,
  arrowCustomMobile,
} from "../../../../../entities/imageHelper";

export const Custom = (props: PropsWithChildren) => {
  const { state, dispatch } = useAppContext();
  const { language } = state;
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        margin: theme.spacing(10, 0),
        [theme.breakpoints.down(1280)]: {
          margin: theme.spacing(10, 5),
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(8, 2),
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: theme.spacing(50),
          marginRight: theme.spacing(7),
          [theme.breakpoints.down("md")]: {
            width: "100%",
            marginBottom: theme.spacing(16),
          },
        }}
      >
        <Typography
          sx={{
            fontSize: theme.spacing(2.5),
            lineHeight: theme.spacing(3),
            [theme.breakpoints.down("md")]: {
              fontSize: theme.spacing(2),
              lineHeight: theme.spacing(2.25),
            },
          }}
        >
          {offers[language].custom.text}
        </Typography>
      </Box>
      {mobile ? (
        <Box
          sx={{
            position: "absolute",
            width: theme.spacing(8),
            height: theme.spacing(10),
            top: theme.spacing(6),
            right: theme.spacing(0),
            transform: "rotate(30deg)",
            [theme.breakpoints.down("sm")]: {
              top: theme.spacing(9),
            },
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={arrowCustomMobile}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            width: theme.spacing(13),
            height: theme.spacing(5),
            bottom: theme.spacing(-1),
            left: theme.spacing(42),
          }}
        >
          <img style={{ width: "100%", height: "100%" }} src={arrowCustom} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: theme.spacing(45),
          border: "1px solid #E3E5FC",
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(4, 3),
          [theme.breakpoints.down("md")]: {
            width: "calc(100% - 48px)",
          },
        }}
      >
        <Typography
          color="primary.main"
          variant="h5"
          sx={{ margin: theme.spacing(0, 0, 3, 0) }}
        >
          {offers[language].custom.title}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            dispatch(updateMessage("Custom Plan"));
            navigate("/contact");
          }}
        >
          {offers[language].custom.button}
        </Button>
      </Box>
    </Box>
  );
};
