import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
import { Updates } from "./pageComponents/Updates";
import { Features } from "./pageComponents/Features";
import { Custom } from "./pageComponents/Custom";
import { Options } from "./pageComponents/Options";
import { Selections } from "./pageComponents/Selections";
import { Content } from "./pageComponents/Content";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    page: "pricing",
  },
  dataLayerName: "PageDataLayer",
};

export const Pricing = (props: PropsWithChildren) => {
  TagManager.dataLayer(tagManagerArgs);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "clip",
        position: "relative",
      }}
    >
      <Content />
      <Selections />
      <Options />
      <Custom />
      <Features />
      <Updates />
    </Box>
  );
};
