import React, { PropsWithChildren } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { info } from "../../../../../helpers/data/pricing";

export const Content = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();
  return (
    <Box
      sx={{
        [theme.breakpoints.down(1280)]: {
          margin: theme.spacing(4, 5, 0),
          width: "80%",
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(4, 2, 0),
        },
      }}
    >
      <Typography
        sx={{
          margin: theme.spacing(1.5, 0),
          fontWeight: 800,
          fontSize: theme.spacing(6),
          lineHeight: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: theme.spacing(4),
          },
        }}
      >
        {info[language].title}
      </Typography>
      <Typography
        sx={{
          fontSize: theme.spacing(2.25),
          lineHeight: 1.5,
          margin: theme.spacing(2, 0),
          textAlign: "center",
        }}
      >
        {info[language].text}
      </Typography>
    </Box>
  );
};
