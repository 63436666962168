export const info = {
  en: {
    title: "Innovative eCommerce solutions for your business",
    text: "Book a 30 minute demo to see how it has helped others in your industry",
    phone: "Telephone",
    address: "Address",
    button: "Book Demo",
  },
  gr: {
    title: "Ας δούμε μαζί τις ανάγκες της online επιχείρησής σας",
    text: "Προγραμματίστε μια δωρεάν online παρουσίαση των 30 λεπτών.",
    phone: "Τηλέφωνο",
    address: "Διεύθυνση",
    button: "Κλείστε demo",
  },
};
export const formInfo = {
  en: {
    title: "Contact Us",
    text: "Fill out this form and we will contact you as soon as possible",
    department: {
      title: "Please choose the department you want to get in touch with.",
      options: ["sales", "support", "info"],
    },
    form: {
      name: "Name",
      email: "Email",
      phone: "Telephone",
      website: "Website Address",
      message: {
        label: "Type your message here",
        content: (text: string) => `${text}`,
      },
      check: {
        text: "I agreee with the ",
        content: "terms and conditions.",
        link: "/tos",
      },
    },
    button: "Send message",
  },
  gr: {
    title: "Επικοινωνήστε μαζί μας",
    text: "Συμπληρώστε την παρακάτω φόρμα και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό.",
    department: {
      title:
        "Παρακαλώ επιλέξτε το τμήμα με το οποίο θέλετε να έρθετε σε επαφή.",
      options: ["sales", "support", "info"],
    },
    form: {
      name: "Ονοματεπώνυμο",
      email: "Email",
      phone: "Τηλέφωνο",
      website: "Website Address",
      message: {
        label: "Μήνυμα",
        content: (text: string) => `${text}`,
      },
      check: {
        text: "Αποδέχομαι τους",
        content: "όρους και προϋποθέσεις.",
        link: "/tos",
      },
    },
    button: "Αποστολή",
  },
};
