import {
  ApiIntegration,
  control,
  healthCheck,
  mapAlert,
  monitor,
  pricing,
} from "../../entities/imageHelper";
export const labels = {
  en: {
    demo: "For eShops on ΜarketPlaces",
    products: "Products",
    competitors: "Competitors",
    experience: "With Know-how",
  },
  gr: {
    demo: "Προβάδισμα στα MarketPlaces",
    products: "Προϊόντα",
    competitors: "Ανταγωνιστές",
    experience: "Με Τεχνογνωσία",
  },
};
export const info = {
  en: {
    title: "Now you are informed about changes in your Market",
    text: "Use smart automations that can offer you real time intel on the market conditions specifically for your catalogue. PriceMonitor gathers for you price and position information of your products, in one dashboard. Now you can monitor competion and adjust your pricing strategy based on unique rules for your business. Built for modern-day eShops & online pharmacies.",
    button: "Book a 30min Demo",
  },
  gr: {
    title: "Πιάστε τον Παλμό της Αγοράς",
    text: "Πλατφόρμα καταγραφής πληροφοριών για τις τιμές του πλήρους καταλόγου σας, σε σύγκριση με των ανταγωνιστών σας, σε μια οθόνη. Είτε θέλετε να κρατήσετε κορυφαίες θέσεις κατάταξης, είτε να διατηρήσετε αυστηρά περιθώρια κέρδους, εργαλείο σας θα είναι το PriceMonitor. Σχεδιασμένο για σύγχρονα eShops & online Φαρμακεία.",
    button: "Κλείστε Demo 30λεπτών",
  },
};
export const keyFeatures = {
  en: [
    {
      icon: monitor,
      title: "Stealth Monitoring",
      text: "Continuous scanning of your products on Greek marketplaces for any change in their price, position and other. Get informed about insights and new competitors in the market without having to search.",
    },
    {
      icon: pricing,
      title: "Smart Pricing",
      text: "Make data-driven commercial decisions with information specifically of your industry and products. Know where your products stand each hour and increase profit margins.",
    },
    {
      icon: healthCheck,
      title: "Health Control",
      text: "More info equals more control. Strengthen your eBusiness with reports that are paramount before moving to commercial decisions and partnerships.",
    },
    {
      icon: ApiIntegration,
      title: "API Integrations",
      text: "Connect the application with other business tools i.e. reporting, analytics even your eCommerce platform or ERP for automatic and dynamic price changes.",
    },
    {
      icon: mapAlert,
      title: "MAP Alerts",
      text: "If you are a supplier, protect your merchandise from Minimun Advertised Price Violations. Get information about when and how many times there is a breach of your MAP policies.",
    },
    {
      icon: control,
      title: "AutoMatching",
      text: "4 easy and fast ways to upload your products. Upload your cataloguein bulk and we will find the matching profile on the marketplaces, saving you from manual work.",
    },
  ],
  gr: [
    {
      icon: monitor,
      title: "Παρακολούθηση αγοράς",
      text: "Καθημερινή και επαναλαμβανόμενη σάρωση των προϊόντων σας στα marketplaces για τις τιμές, τις θέσεις και άλλες εμπορικές πληροφορίες. Ενημερωθείτε για την κατάσταση της αγοράς, για παλιούς και νέους ανταγωνιστές, χωρίς να ψάξετε.",
    },
    {
      icon: pricing,
      title: "Έξυπνη τιμοδότηση",
      text: "Λάβετε εμπορικές αποφάσεις βάσει δεδομένων αποκλειστικά για τον κλάδο και τα προϊόντα σας. Αυξήστε τα περιθώρια κέρδους τις ώρες που έχετε το μονοπώλιο.",
    },
    {
      icon: healthCheck,
      title: "Ολιστικός έλεγχος",
      text: "Ενδυναμώστε την ηλεκτρονική σας παρουσία χρησιμοποιώντας δεδομένα, insights και reports πριν από σημαντικές εμπορικές αποφάσεις.",
    },
    {
      icon: ApiIntegration,
      title: "API διασυνδέσεις",
      text: "Διασυνδέστε την εφαρμογή με εργαλεία reporting, analytics ή την ecommerce Πλατφόρμα σας και το ERP σας για αυτόματη και δυναμική αλλαγή των τιμών.",
    },
    {
      icon: mapAlert,
      title: "Ειδοποιήσεις παραβάσεων",
      text: "Προστασία από αθέμιτο ανταγωνισμό αλλά και για χρήση από προμηθευτές που θέλουν να προστατέψουν τον κατάλογό τους. Λάβετε πληροφορίες σχετικά με το πότε και πόσες φορές υπάρχει παραβίαση της ΠΛΤ πολιτικής σας.",
    },
    {
      icon: control,
      title: "Αυτοματοποιημένη αντιστοίχηση",
      text: "4 εύκολοι και γρήγοροι τρόποι για να ανεβάσετε τα προϊόντα σας. Ανεβάστε μαζικά τον κατάλογό σας και θα βρούμε εμείς το αντίστοιχο προίον στα marketplaces, γλυτώνοντας σας χρόνο από χειρωνακτική εργασία.",
    },
  ],
};
export const products = {
  en: {
    title: "Your Catalogue's Analysis",
    accordions: [
      {
        title: "Products' Grid",
        text: "Holistic view of all your products in 1 screen with updates about their status in relation to price, number of competitors, amount of profit, position in marketplaces, etc.",
      },
      {
        title: "Easy to use Interface",
        text: "Simple design that is easy to learn. There is no need for training as most teams feel comfortable after 1-2 days of use.",
      },
      {
        title: "Gain momentum with Smart Pricing",
        text: "The Smart Pricing feature will auto calculate the most profitable price or position for each of your products, based on the latest marketplace info. Set once your profit margin rules and pricing strategies and let the automation work everyday for optimal price proposals.",
      },
      {
        title: "Solely eCommerce data",
        text: "It's now easy to focus on the best-performing products and strategies with reports created with your data and not market generic information. Share with your team clear and easy-to-read graphs to discuss findings.",
      },
    ],
  },
  gr: {
    title: "Ανάλυση του καταλόγου σας",
    accordions: [
      {
        title: "Πίνακας Προϊόντων",
        text: "Ολιστική προβολή των προϊόντων σας σε 1 οθόνη, με updates σχετικά με αλλάγες σε σχέση με την τιμή, τον αριθμό των ανταγωνιστών, το ποσό κέρδους, τη θέση στα marketplaces κ.α.",
      },
      {
        title: "Εύκολη διαχείρηση για όλους τους χρήστες",
        text: "Σχεδιασμένο ώστε να είναι απλό και εύχρηστο. Δεν υπάρχει ανάγκη για εκπαίδευση καθώς οι περισσότεροι χρήστες το χρησιμοποιούν με άνεση μετά από 1 - 2 ημέρες χρήσης.",
      },
      {
        title: "Αποκτήστε δυναμική με την Smart Τιμοδότηση",
        text: "Η Smart Τιμοδότηση υπολογίζει αυτόματα την πιο κερδοφόρα τιμή ή θέση στα MarketPlaces για τα προϊόντα σας, βάσει των κανόνων ποσοστού κέρδους και των στρατηγικών τιμολόγησης.",
      },
      {
        title: "Καθαρά eCommerce δεδομένα",
        text: "Είναι πλέον εύκολο να εστιάσετε στα προϊόντα με την καλύτερη απόδοση χάρη στα αυτόματα reports που αφορούν τα δικά σας δεδομένα και συμφέροντα. Μοιραστείτε με την ομάδα σας σαφή και ευανάγνωστα γραφήματα για την ομαδική ανάλυση ευρημάτων.",
      },
    ],
  },
};
export const competitors = {
  en: {
    title: "Your Competitors' Analysis",
    accordions: [
      {
        title: "Competitors' Grid",
        text: "Holistic view of all your competitors and their products' performance. Useful analytics on: number of shared products, average price, average position in marketplaces and more.",
      },
      {
        title: "Competitor's Break-down",
        text: "Detailed analysis for each competitive eBusiness, with summmed information as: common products, categories, brands, average ranking and more.",
      },
      {
        title: "Competitive Strategy with Data",
        text: "Analyze the longterm strategy your competition follows. Such as: when and how they apply changes, they increase or decrease their catalog or their prices and when a player entered or exited your market.",
      },
    ],
  },
  gr: {
    title: "Ανάλυση της αγοράς σας",
    accordions: [
      {
        title: "Πίνακας Ανταγωνιστών",
        text: "Ολιστική προβολή του συνόλου των ανταγωνιστών και των επιδόσεών τους. Χρήσιμες αναλύσεις για τον αριθμό κοινών προϊόντων, τη μέση τιμή, τη μέση θέση σε MarketPlaces κ.α.",
      },
      {
        title: "Ανάλυση ανά Ανταγωνιστή",
        text: "Λεπτομερής ανάλυση για κάθε ανταγωνιστικό eshop, με τα συνόλα: κοινών προϊόντων, κατηγοριών, brands, μέσων κατατάξεων κ.α.",
      },
      {
        title: "Ανταγωνιστική Στρατηγική με Δεδομένα",
        text: "Αναλύστε την μακροπρόθεση στρατηγική που ακολουθεί ο ανταγωνισμός σας. Όπως: πότε και πως εφαρμόζονται αλλάγες, αυξήσεις ή μειώσεις στον αριθμό του καταλογού ή στις τιμές καθώς και πότε εισχώρησε ή αποχώρησε ένας έμπορος από την αγορά σας.",
      },
    ],
  },
};
export const experience = {
  en: {
    title: "Built upon long experience in Greek eCommerce",
    text: "The idea and the development of PriceMonitor comes from a team with more than 10 years of experience in large eCommerce projects. It started out as an internal application to reinfornce selected eCommerce businesses and now is open to help eveyone in the market.",
  },
  gr: {
    title: "Βασισμένο σε πολυετή εμπειρία στο ηλεκτρονικό εμπόριο",
    text: "Η ιδέα και η υλοποίηση της πλατφόρμας έρχεται σε εσάς από μια βραβευμένη ομάδα από ανθρώπους με εμπειρία άνω των 10 ετών στην ανάπτυξη μεγάλων eCommerce έργων. Ξεκίνησε σαν εσωτερικό εργαλείο για την ενδυνάμωση συγκεκριμένων επιχειρήσεων, είναι πλέον διαθέσιμο για όλους στην αγορά του ηλεκτρονικού εμπορίου.",
  },
};
export const contact = {
  en: {
    title: "See how you can benefit",
    text: "Book a 30 minute personalised demo with a member of our team.",
    button: "Contact us",
  },
  gr: {
    title: "Δείτε κι εσείς τα οφέλη",
    text: "Προσωποποιημένη online παρουσίαση, με ένα μέλος της ομάδας μας, σε 30 λεπτά.",
    button: "Επικοινωνία",
  },
};
