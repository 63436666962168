import { darken, ThemeOptions } from "@mui/material";

import { IPalette, ThemeMode } from "./palette/IPalette";
import themePalette from "./palette/themePaletteMode";

interface IApplicationTheme {
  (
    color: keyof IPalette,
    mode: ThemeMode,
    direction: "rtl" | "ltr"
  ): ThemeOptions;
}

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 980,
  lg: 1380,
  xl: 1920,
};

const applicationTheme: IApplicationTheme = (color, mode, direction) => ({
  breakpoints: {
    values: breakpointValues,
  },
  direction,
  palette: {
    mode,
    primary: themePalette(color, mode).palette.primary,
    secondary: themePalette(color, mode).palette.secondary,
    warning: themePalette(color, mode).palette.warning,
    success: themePalette(color, mode).palette.success,
    error: themePalette(color, mode).palette.error,
    input: themePalette(color, mode).palette.input,
    lime: themePalette(color, mode).palette.lime,
    gray: themePalette(color, mode).palette.gray,
  },
  typography: {
    // useNextVariants: true,
    fontFamily: ["Commissioner", "sans-serif"].join(","),
  },
  shadows: [
    "none",
    "0px 2px 9px 0px rgba(14, 0, 99, 0.12)", // shadow for paper
    "2px 7px 32px 0px rgba(14, 0, 99, 0.5)", // shadow for item in automatch
    "0px 1px 3px 0px rgba(50,50,50, 0.2),0px 1px 1px 0px rgba(50,50,50, 0.14),0px 2px 1px -1px rgba(50,50,50, 0.12)",
    "0px 1px 5px 0px rgba(50,50,50, 0.2),0px 2px 2px 0px rgba(50,50,50, 0.14),0px 3px 1px -2px rgba(50,50,50, 0.12)",
    "0px 1px 8px 0px rgba(50,50,50, 0.2),0px 3px 4px 0px rgba(50,50,50, 0.14),0px 3px 3px -2px rgba(50,50,50, 0.12)",
    "0px 2px 4px -1px rgba(50,50,50, 0.2),0px 4px 5px 0px rgba(50,50,50, 0.14),0px 1px 10px 0px rgba(50,50,50, 0.12)",
    "0px 3px 5px -1px rgba(50,50,50, 0.2),0px 5px 8px 0px rgba(50,50,50, 0.14),0px 1px 14px 0px rgba(50,50,50, 0.12)",
    "0px 3px 5px -1px rgba(50,50,50, 0.2),0px 6px 10px 0px rgba(50,50,50, 0.14),0px 1px 18px 0px rgba(50,50,50, 0.12)",
    "0px 4px 5px -2px rgba(50,50,50, 0.2),0px 7px 10px 1px rgba(50,50,50, 0.14),0px 2px 16px 1px rgba(50,50,50, 0.12)",
    "0px 5px 5px -3px rgba(50,50,50, 0.2),0px 8px 10px 1px rgba(50,50,50, 0.14),0px 3px 14px 2px rgba(50,50,50, 0.12)",
    "0px 5px 6px -3px rgba(50,50,50, 0.2),0px 9px 12px 1px rgba(50,50,50, 0.14),0px 3px 16px 2px rgba(50,50,50, 0.12)",
    "0px 6px 6px -3px rgba(50,50,50, 0.2),0px 10px 14px 1px rgba(50,50,50, 0.14),0px 4px 18px 3px rgba(50,50,50, 0.12)",
    "0px 6px 7px -4px rgba(50,50,50, 0.2),0px 11px 15px 1px rgba(50,50,50, 0.14),0px 4px 20px 3px rgba(50,50,50, 0.12)",
    "0px 7px 8px -4px rgba(50,50,50, 0.2),0px 12px 17px 2px rgba(50,50,50, 0.14),0px 5px 22px 4px rgba(50,50,50, 0.12)",
    "0px 7px 8px -4px rgba(50,50,50, 0.2),0px 13px 19px 2px rgba(50,50,50, 0.14),0px 5px 24px 4px rgba(50,50,50, 0.12)",
    "0px 7px 9px -4px rgba(50,50,50, 0.2),0px 14px 21px 2px rgba(50,50,50, 0.14),0px 5px 26px 4px rgba(50,50,50, 0.12)",
    "0px 8px 9px -5px rgba(50,50,50, 0.2),0px 15px 22px 2px rgba(50,50,50, 0.14),0px 6px 28px 5px rgba(50,50,50, 0.12)",
    "0px 8px 10px -5px rgba(50,50,50, 0.2),0px 16px 24px 2px rgba(50,50,50, 0.14),0px 6px 30px 5px rgba(50,50,50, 0.12)",
    "0px 8px 11px -5px rgba(50,50,50, 0.2),0px 17px 26px 2px rgba(50,50,50, 0.14),0px 6px 32px 5px rgba(50,50,50, 0.12)",
    "0px 9px 11px -5px rgba(50,50,50, 0.2),0px 18px 28px 2px rgba(50,50,50, 0.14),0px 7px 34px 6px rgba(50,50,50, 0.12)",
    "0px 9px 12px -6px rgba(50,50,50, 0.2),0px 19px 29px 2px rgba(50,50,50, 0.14),0px 7px 36px 6px rgba(50,50,50, 0.12)",
    "0px 10px 13px -6px rgba(50,50,50, 0.2),0px 20px 31px 3px rgba(50,50,50, 0.14),0px 8px 38px 7px rgba(50,50,50, 0.12)",
    "0px 10px 13px -6px rgba(50,50,50, 0.2),0px 21px 33px 3px rgba(50,50,50, 0.14),0px 8px 40px 7px rgba(50,50,50, 0.12)",
    "0px 10px 14px -6px rgba(50,50,50, 0.2),0px 22px 35px 3px rgba(50,50,50, 0.14),0px 8px 42px 7px rgba(50,50,50, 0.12)",
  ],

  components: {
    // Global
    MuiCssBaseline: {
      styleOverrides: {
        "*, *::before, *::after": {
          boxSizing: "initial",
        },
        // HTML #ROOT MAIN
        "html,#root, main": {
          height: "100%",
        },
        "&::-webkit-scrollbar": {
          width: 3,
          height: 3,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: themePalette(color, mode).palette.primary.light,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          borderRadius: 16,
        },
        // Body
        body: {
          height: "100%",
          margin: 0,
          "&::-webkit-scrollbar": {
            width: 8,
            height: 8,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: themePalette(color, mode).palette.primary.light,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: themePalette(color, mode).palette.primary.dark,
            borderRadius: 16,
          },
          fontFamily: "Commissioner,sans-serif !important",
          // Recharts
          ".recharts-wrapper svg": {
            overflow: "inherit !important",
          },
          ".recharts-pie": {
            width: "400px",
            height: "400px",
          },
        },
      },
    },

    // Components

    // PAGINATION
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: "25px",
          minWidth: "unset",
          height: "25px",
          borderRadius: "20px",
          padding: 0,
          lineHeight: 1,
          fontWeight: 600,
          background: darken(
            themePalette(color, mode).palette.input.light,
            0.03
          ),
          "&:hover": {
            background: themePalette(color, mode).palette.input.dark,
            color: themePalette(color, mode).palette.input.contrastText,
          },
          color: themePalette(color, mode).palette.input.dark,
          "&.Mui-selected": {
            background: themePalette(color, mode).palette.primary.dark,
            color: themePalette(color, mode).palette.input.contrastText,
            fontSize: "14px",
            fontWeight: 700,
            "&:hover": {
              background: themePalette(color, mode).palette.input.dark,
            },
          },
        },
        ellipsis: {
          background: themePalette(color, mode).palette.input.contrastText,
          color: themePalette(color, mode).palette.input.dark,
          "&:hover": {
            background: "unset",
            color: themePalette(color, mode).palette.input.dark,
          },
        },
        previousNext: {
          background: themePalette(color, mode).palette.input.contrastText,
          color: themePalette(color, mode).palette.primary.dark,
          "&:hover": {
            background: darken(
              themePalette(color, mode).palette.input.light,
              0.03
            ),
            color: "unset",
          },
        },
      },
    },

    // INPUT
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: themePalette(color, mode).palette.input.light,
          "&:hover:not(.Mui-disabled)": {
            background: themePalette(color, mode).palette.input.main,
          },
          "&.Mui-focused": {
            border: `${themePalette(color, mode).palette.input.main}`, // #7C67F833,
            boxShadow: `0px 0px 0px 2px ${
              themePalette(color, mode).palette.input.main
            }`, // #7C67F833
            background: themePalette(color, mode).palette.input.light,
            borderRadius: 4,
            "&:before": {
              border: "none !important",
            },
            "&:after": {
              border: "none",
            },
          },
          "&.Mui-error": {
            border: `${themePalette(color, mode).palette.error.main}`, // "1px solid red",
            boxShadow: `0px 0px 0px 2px ${
              themePalette(color, mode).palette.error.main
            }`, // "0px 0px 0px 2px #D43C7633",
            background: themePalette(color, mode).palette.error.light, // "#D43C760F",
            borderRadius: 4,
            "&:before": {
              border: "none !important",
            },
            "&:after": {
              border: "none",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
          "&:focus": {
            background: "none",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
        },
      },
    },

    // TYPOGRAPHY
    MuiTypography: {
      styleOverrides: {
        root: {
          color: themePalette(color, mode).palette.primary.dark,
          "&.Mui-selected": {
            color: themePalette(color, mode).palette.primary.dark,
          },
        },
        // Large price
        h1: {
          fontSize: "2.5rem",
          fontWeight: 700,
        },
        // Title
        h2: {
          fontSize: "1.5rem",
          fontWeight: 700,
        },
        // Title Internal
        h3: {
          fontSize: "1.25rem",
          fontWeight: 700,
        },
        // Subtitle/Tab Bold
        h4: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        // Subtitle/Tab
        h5: {
          fontSize: "1rem",
          fontWeight: 700,
        },
        // Search bar/Tags
        h6: {
          fontSize: "0.875rem",
          fontWeight: 700,
        },
        // Body
        body1: {
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "1.25rem",
        },
        // Body smaller
        body2: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "1.125rem",
        },
        // Tooltips
        subtitle1: {
          fontSize: "0.75rem",
          fontWeight: 400,
        },
        // Breadcrump
        subtitle2: {
          fontSize: "0.75rem",
          fontWeight: 300,
        },
        button: {
          fontSize: "0.875rem",
          fontWeight: 400,
        },
        caption: {
          fontSize: "0.825rem",
        },
      },
    },

    // vkon
    // BUTTON
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: 24,
          fontWeight: 700,
          transition: "0.2s",
          contained: {
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            "&:active": {
              boxShadow: "none",
            },
          },
        },
        text: {
          color: themePalette(color, mode).palette.primary.dark,
        },
        outlinedPrimary: {
          color: themePalette(color, mode).palette.primary.dark,
        },
        containedPrimary: {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          color: themePalette(color, mode).palette.primary.contrastText,
          boxShadow: `1px 4px 0px #0B0626`,
          "&:hover": {
            backgroundColor: themePalette(color, mode).palette.primary.dark,
            boxShadow: `1px 5px 0px #0B0626`,
            transform: "translateY(-1px)",
          },
          "&:active": {
            boxShadow: `1px 2px 0px #0B0626`,
            transform: "translateY(2px)",
          },
        },
        containedSecondary: {
          backgroundColor: themePalette(color, mode).palette.secondary.main,
          color: themePalette(color, mode).palette.secondary.contrastText,
          boxShadow: `1px 4px 0px #972450`,
          "&:hover": {
            backgroundColor: themePalette(color, mode).palette.secondary.main,
            boxShadow: `1px 5px 0px #972450`,
            transform: "translateY(-1px)",
          },
          "&:active": {
            boxShadow: `1px 2px 0px #972450`,
            transform: "translateY(2px)",
          },
        },
        containedInfo: {
          backgroundColor: themePalette(color, mode).palette.primary
            .contrastText,
          color: "#8774F6",
          boxShadow: `1px 4px 0px #6353BE`,
          "&:hover": {
            backgroundColor: themePalette(color, mode).palette.input.main,
            boxShadow: `1px 5px 0px #6353BE`,
            transform: "translateY(-1px)",
          },
          "&:active": {
            boxShadow: `1px 2px 0px #6353BE`,
            transform: "translateY(2px)",
          },
        },
      },
    },

    // CHECKBOX
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 4,
          color: themePalette(color, mode).palette.primary.dark,
          "& svg": {
            width: 16,
            height: 16,
          },
          "&.Mui-checked": {
            color: themePalette(color, mode).palette.primary.dark,
          },
          "&.Mui-indeterminnate": {
            color: themePalette(color, mode).palette.primary.dark,
          },
          "&.Mui-disabled": {
            color: themePalette(color, mode).palette.primary.light,
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    // RADIO
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 4,
          color: themePalette(color, mode).palette.primary.dark,
          "& svg": {
            height: 16,
            width: 16,
          },
          "&.Mui-checked": {
            color: themePalette(color, mode).palette.primary.dark,
          },
          "&.Mui-disabled": {
            color: themePalette(color, mode).palette.primary.light,
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    // SWITCH
    // TODO Update colors
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 21,
          padding: 0,
        },
        switchBase: {
          // margin: "1px",
          padding: "2px",
          color: themePalette(color, mode).palette.primary.dark,
          "&.Mui-checked": {
            color: themePalette(color, mode).palette.primary.contrastText,
            transform: "translateX(18px)",
          },
          "&.Mui-checked+.MuiSwitch-track": {
            background: themePalette(color, mode).palette.primary.dark,
          },
          "&.Mui-disabled+.MuiSwitch-track": {
            background: themePalette(color, mode).palette.primary.light,
            border: `1px solid ${
              themePalette(color, mode).palette.primary.light
            } !important`,
          },
        },
        thumb: {
          width: 17,
          height: 17,
          boxShadow: "none",
          "& .Mui-disabled": {
            color: themePalette(color, mode).palette.primary.contrastText,
          },
        },
        track: {
          height: "calc(100% - 2px)",
          border: `1px solid ${themePalette(color, mode).palette.primary.dark}`,
          borderRadius: 12,
          background: themePalette(color, mode).palette.primary.contrastText,
          opacity: "1 !important",
          "&.Mui-checked": {
            background: themePalette(color, mode).palette.primary.dark,
            backgroundColor: themePalette(color, mode).palette.primary.dark,
          },
        },
      },
    },

    // POPOVER
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: themePalette(color, mode).palette.input.light,
        },
      },
    },

    // vkon
    // ICON BUTTON
    MuiIconButton: {
      defaultProps: {
        size: "medium",
      },
      styleOverrides: {
        sizeSmall: {
          padding: "6px",
        },
        sizeMedium: {
          padding: "8px",
        },
        sizeLarge: {
          padding: "10px",
        },
      },
    },

    // vkon
    // TOOLTIP
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "bottom",
      },
      styleOverrides: {
        arrow: {
          color: themePalette(color, mode).palette.primary.dark,
        },
        tooltip: {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          color: themePalette(color, mode).palette.primary.contrastText,
          fontSize: 12,
        },
      },
    },

    // vkon
    // SLIDER
    MuiSlider: {
      styleOverrides: {
        markActive: {
          color: themePalette(color, mode).palette.primary.light,
        },
        mark: {
          color: themePalette(color, mode).palette.primary.dark,
        },
        markLabel: {
          color: themePalette(color, mode).palette.primary.light,
          "&.MuiSlider-markLabelActive": {
            color: themePalette(color, mode).palette.primary.dark,
          },
        },
      },
    },

    // vkon
    // DATERANGE PICKER
    MuiDateRangePickerDay: {
      styleOverrides: {
        rangeIntervalDayHighlight: {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          "& > div > button": {
            color: themePalette(color, mode).palette.primary.contrastText,
          },
        },
      },
    },

    // vkon
    // DATA GRID
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: "8px 8px 0 0 !important",
          border: "none",
        },
        columnHeader: {
          color: themePalette(color, mode).palette.primary.contrastText,
        },
        columnsContainer: {
          borderRadius: "8px 8px 0 0 !important",
        },
        pinnedColumnHeaders: {
          backgroundColor: themePalette(color, mode).palette.primary.dark,
        },
        columnHeaders: {
          borderRadius: "8px 8px 0 0 !important",
          backgroundColor: themePalette(color, mode).palette.primary.dark,
          "& > div > div > div": {
            "& .MuiDataGrid-checkboxInput": {
              color: themePalette(color, mode).palette.primary.light,
            },
          },
        },
        pinnedColumns: {
          boxShadow: "2px 7px 32px 0px rgb(14 0 99 / 25%) !important",
        },
        checkboxInput: {
          color: themePalette(color, mode).palette.primary.dark,
        },
        iconButtonContainer: {
          "& > button": {
            color: themePalette(color, mode).palette.primary.contrastText,
          },
        },
        footerContainer: {
          justifyContent: "center !important",
        },
        cell: {
          borderBottom: "none !important",
          "&:focus": {
            outline: "none !important",
          },
          "&:focus-within": {
            outline: "none !important",
          },
          "&:focus-visible": {
            outline: "none !important",
          },
        },
        row: {
          "&:nth-of-type(even)": {
            background: themePalette(color, mode).palette.input.light,
          },
        },
      },
    },
  },
});

export default applicationTheme;
