import React, { PropsWithChildren, useMemo, useRef } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { offers } from "../../../../../helpers/data/pricing";
import { Option } from "./Option";
import Swiper from "react-id-swiper";
import { capitalizeFirstLetter } from "../../../utils";
import { pricingCarousel } from "../../../../../entities/imageHelper";

export const Options = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language, plan } = state;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up(1280));
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          display: desktop || mobile ? "none" : "block",
          [theme.breakpoints.down(1100)]: { width: "88%" },
          left: "5%",
        }}
      >
        <img
          src={pricingCarousel}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            position: "relative",
            minWidth: 0,
            // justifyContent: "space-between",
            // width: theme.spacing(180),
            margin: theme.spacing(2, 0),
          }}
        >
          {desktop ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                position: "relative",
                "& >*:not(:last-child)": {
                  marginRight: theme.spacing(3),
                },
              }}
            >
              {offers[language].offers.map((offer) => (
                <Box
                  key={`${offer.title}-plan`}
                  component="div"
                  sx={{
                    width: theme.spacing(33) + "!important",
                    [theme.breakpoints.down(1440)]: {
                      width: theme.spacing(30) + "!important",
                    },
                  }}
                >
                  <Option offer={offer} />
                </Box>
              ))}
            </Box>
          ) : (
            <Swiper
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              activeSlideKey={`${capitalizeFirstLetter(plan)}-plan`}
              spaceBetween={16}
            >
              {offers[language].offers.map((offer) => (
                <Box
                  key={`${offer.title}-plan`}
                  component="div"
                  sx={{ width: theme.spacing(33) + "!important" }}
                >
                  <Option offer={offer} />
                </Box>
              ))}
            </Swiper>
          )}
        </Box>
      </Box>
    </Box>
  );
};
