import React, { PropsWithChildren } from "react";
import { Box, useTheme, Typography, Button } from "@mui/material";
import { contact } from "../../../../../helpers/data/home";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { contactHomeBG } from "../../../../../entities/imageHelper";

export const Contact = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: `url(${contactHomeBG})`,
        backgroundRepeat: "no-repeat",
        borderRadius: theme.spacing(1.5),
        marginLeft: theme.spacing(10),
        width: theme.spacing(59),
        height: theme.spacing(47),
        padding: theme.spacing(4.75, 0, 0, 4),
        [theme.breakpoints.down(1760)]: {
          marginLeft: theme.spacing(0),
        },
        [theme.breakpoints.down("md")]: {
          width: "100% !important",
        },
      }}
    >
      <Typography
        sx={{
          margin: theme.spacing(1.5, 0),
          fontWeight: 800,
          fontSize: theme.spacing(6),
          lineHeight: 1,
          color: "white",
          [theme.breakpoints.down("md")]: {
            fontSize: theme.spacing(4),
          },
        }}
      >
        {contact[language].title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          color: "white",
          lineHeight: "28px",
          fontSize: "20px",
          mr: 1.5,
        }}
        variant="body1"
      >
        {contact[language].text}
      </Typography>
      <Button
        href={`https://calendly.com/pricemonitor/30min`}
        target="_blank"
        variant="contained"
        color="info"
        sx={{ marginTop: theme.spacing(4) }}
      >
        {contact[language].button}
        <ChevronRightIcon sx={{ color: "#8774F6" }} />
      </Button>
    </Box>
  );
};
