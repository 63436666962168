import React, { PropsWithChildren } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { ColorLabel } from "./ColorLabel";
import { Contact } from "./Contact";
import { experience } from "../../../../../helpers/data/home";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { experienceHome } from "../../../../../entities/imageHelper";

export const Expertise = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(-10),
        [theme.breakpoints.down(1280)]: {
          flexDirection: "column",
          alignItems: "center",
        },
        [theme.breakpoints.down(1440)]: {
          marginTop: theme.spacing(0),
          width: "calc(100% - 80px)",
        },
        [theme.breakpoints.down("md")]: {
          width: "calc(100% - 32px)",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }}
      id="experience"
    >
      <Box
        sx={{
          background: "#F4F4F8",
          borderRadius: theme.spacing(1.5),
          width: theme.spacing(100),
          height: theme.spacing(50),
          padding: theme.spacing(8, 0, 0, 8),
          display: "flex",
          justifyContent: "space-between",
          marginRight: theme.spacing(10),
          [theme.breakpoints.down(1440)]: {
            marginRight: theme.spacing(0),
            width: theme.spacing(80),
            padding: theme.spacing(5, 0, 0, 5),
          },
          [theme.breakpoints.down("md")]: {
            width: "calc(100% - 24px) !important",
            height: "auto",
            flexDirection: "column",
            padding: theme.spacing(7, 0, 0, 3),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: theme.spacing(70),
            [theme.breakpoints.down("md")]: {
              width: "100% !important",
            },
          }}
        >
          <ColorLabel color="green" />
          <Typography
            sx={{
              margin: theme.spacing(2, 0),
              fontWeight: 800,
              fontSize: theme.spacing(6),
              lineHeight: 1,
              [theme.breakpoints.down("md")]: {
                fontSize: theme.spacing(4),
              },
              [theme.breakpoints.down(1440)]: {
                fontSize: theme.spacing(4),
              },
            }}
          >
            {experience[language].title}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, lineHeight: "22px" }}
            variant="body1"
          >
            {experience[language].text}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <Box
            sx={{
              width: theme.spacing(25),
              height: theme.spacing(35),
              [theme.breakpoints.down("md")]: {
                margin: "24px 0 0 auto",
              },
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={experienceHome}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          marginTop: theme.spacing(20),
          [theme.breakpoints.down("lg")]: {
            marginTop: theme.spacing(10),
          },
        }}
      >
        <Contact />
      </Box>
    </Box>
  );
};
