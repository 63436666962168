import React, { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";
import { Demo } from "./pageComponents/Demo";
import { KeyFeatures } from "./pageComponents/KeyFeatures";
import { Products } from "./pageComponents/Products";
import { Competitors } from "./pageComponents/Competitors";
import { Expertise } from "./pageComponents/Expertise";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    page: "home",
  },
  dataLayerName: "PageDataLayer",
};

export const Home = (props: PropsWithChildren) => {
  TagManager.dataLayer(tagManagerArgs);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowX: "clip",
        "& >*": {
          padding: theme.spacing(5, 0, 0),
          marginBottom: theme.spacing(15),
          [theme.breakpoints.down(1440)]: {
            marginBottom: theme.spacing(10),
          },
          [theme.breakpoints.down(1360)]: {
            marginBottom: theme.spacing(7),
          },
        },
      }}
    >
      <Demo />
      <KeyFeatures />
      <Products />
      <Competitors />
      <Expertise />
    </Box>
  );
};
