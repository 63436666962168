import React, { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";
import { ColorLabel } from "./ColorLabel";
import { Accordions } from "./Accordions";
import { competirorsHome } from "../../../../../entities/imageHelper";

export const Competitors = (props: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(-15),
        [theme.breakpoints.down(1780)]: {
          paddingLeft: theme.spacing(5),
          paddingRight: theme.spacing(5),
        },
        [theme.breakpoints.down(1360)]: {
          marginTop: theme.spacing(0),
        },
        [theme.breakpoints.down(1280)]: {
          flexDirection: "column-reverse",
        },
        [theme.breakpoints.down("md")]: {
          marginRight: theme.spacing(0),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }}
      id="competitors"
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down(1360)]: {
            marginTop: theme.spacing(-8),
          },
          [theme.breakpoints.down(1280)]: {
            minWidth: theme.spacing(50),
            marginLeft: "unset",
          },
          [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(0),
            minWidth: "unset !important",
          },
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: theme.spacing(150),
            height: "auto",
          }}
          src={competirorsHome}
        />
      </Box>
      <Box>
        <ColorLabel color="red" />
        <Accordions case="competitors" />
      </Box>
    </Box>
  );
};
