import React, { PropsWithChildren } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Booking } from "./pageComponents/Booking";
import { Form } from "./pageComponents/Form";
import TagManager from "react-gtm-module";
import { Info } from "./pageComponents/Info";

const tagManagerArgs = {
  dataLayer: {
    page: "contact",
  },
  dataLayerName: "PageDataLayer",
};

export const Contact = (props: PropsWithChildren) => {
  TagManager.dataLayer(tagManagerArgs);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: theme.spacing(10, 30),
        [theme.breakpoints.down(1440)]: {
          margin: theme.spacing(7, 10),
        },
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(5, 2),
          flexDirection: "column",
        },
      }}
    >
      <Booking />
      <Form />
      {mobile && <Info />}
    </Box>
  );
};
