import React, { PropsWithChildren } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ColorLabel } from "./ColorLabel";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { info } from "../../../../../helpers/data/home";
import { home, squares } from "../../../../../entities/imageHelper";
import { CalendarIcon } from "../../../../../entities/iconHelper";

export const Demo = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "80%",
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          width: "calc(100% - 32px)",
        },
      }}
      id="#"
    >
      <Box
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(8),
          marginLeft: theme.spacing(50),
          [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: "auto",
          },
        }}
      >
        <img style={{ width: "100%", height: "100%" }} src={squares} />
      </Box>
      <Box
        sx={{
          [theme.breakpoints.down("md")]: {
            marginRight: "auto",
          },
        }}
      >
        <ColorLabel color="orange" />
      </Box>
      <Typography
        sx={{
          margin: theme.spacing(1.5, 0),
          fontWeight: 800,
          fontSize: theme.spacing(6),
          textAlign: "center",
          lineHeight: 1,
          [theme.breakpoints.down("md")]: {
            fontSize: theme.spacing(4),
          },
        }}
        variant="h1"
      >
        {info[language].title}
      </Typography>
      <Typography
        sx={{
          fontSize: theme.spacing(2.25),
          lineHeight: 1.5,
          width: theme.spacing(120),
          margin: theme.spacing(2, 0),
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
      >
        {info[language].text}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        href={`https://calendly.com/pricemonitor/30min`}
        target="_blank"
        sx={{
          width: theme.spacing(30),
          padding: theme.spacing(1.5, 0),
          margin: theme.spacing(5, 0),
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
      >
        <CalendarIcon />
        <Typography
          sx={{ margin: theme.spacing(0, 1), fontWeight: 700 }}
          variant="body2"
          color="primary.contrastText"
        >
          {info[language].button}
        </Typography>
        <ChevronRightIcon
          fontSize="small"
          sx={{
            color: theme.palette.primary.contrastText,
            fontWeight: 700,
          }}
        />
      </Button>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          margin: theme.spacing(8, 0, 0),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            width: "100%",
            maxWidth: theme.spacing(150),
            height: "auto",
          }}
          src={home}
        />
      </Box>
    </Box>
  );
};
