import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Box,
  useTheme,
  Typography,
  Slider,
  useMediaQuery,
} from "@mui/material";
import {
  changeAnnual,
  changeSelectedPlan,
  changeSelectedPlanSlider,
  useAppContext,
} from "../../../../layout/AppProvider/BaseProvider";
import { selectors } from "../../../../../helpers/data/pricing";
import {
  arrowDiscount,
  arrowDiscountMobile,
} from "../../../../../entities/imageHelper";

export const Selections = (props: PropsWithChildren) => {
  const { state, dispatch } = useAppContext();
  const { language, products, annual } = state;
  const theme = useTheme();
  const [value, setValue] = React.useState<number>(1);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const updatedValue = useMemo(() => {
    switch (products) {
      case 100:
        if (value !== 1) return setValue(1);
        return value;
      case 500:
        if (value !== 2) return setValue(2);
        return value;
      case 1000:
        if (value !== 3) return setValue(3);
        return value;
      case 5000:
        if (value !== 4) return setValue(4);
        return value;
      case 10000:
        if (value !== 5) return setValue(5);
        return value;
      default:
        return setValue(1);
    }
  }, [products]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      if (newValue === 0) return setValue(1);
      return setValue(newValue);
    }
  };

  const sliderSelectionFormat = useCallback((value: number) => {
    switch (value) {
      case 1:
        return selectors[language].slider(100);
      case 2:
        return selectors[language].slider(500);
      case 3:
        return selectors[language].slider(1000);
      case 4:
        return selectors[language].slider(5000);
      case 5:
        return selectors[language].slider(10000);
      default:
        return ``;
    }
  }, []);

  const updatePlan = (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    switch (value) {
      case 1:
        dispatch(changeSelectedPlan("starter"));
        dispatch(changeSelectedPlanSlider(100));
        return undefined;
      case 2:
        dispatch(changeSelectedPlan("essential"));
        dispatch(changeSelectedPlanSlider(500));
        return undefined;
      case 3:
        dispatch(changeSelectedPlan("teams"));
        dispatch(changeSelectedPlanSlider(1000));
        return undefined;
      case 4:
        dispatch(changeSelectedPlan("professional"));
        dispatch(changeSelectedPlanSlider(5000));
        return undefined;
      case 5:
        dispatch(changeSelectedPlan("enterprise"));
        dispatch(changeSelectedPlanSlider(10000));
        return undefined;
      default:
        return undefined;
    }
  };

  const left = useCallback(() => {
    switch (value) {
      case 1:
        return { left: 0, "&:before": { left: "20px" } };

      case 5:
        return {
          left: "unset",
          right: 0,
          "&:before": { left: "unset", right: "12px" },
        };
      default:
        break;
    }
  }, [value]);

  return (
    <>
      <Box
        sx={{
          margin: theme.spacing(10, 0),
          display: "flex",
          justofyContent: "center",
          alignItems: "flex-end",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            margin: theme.spacing(7, 0),
          },
        }}
      >
        <Box
          sx={{
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(4),
            padding: theme.spacing(1),
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: theme.spacing(5),
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              padding: theme.spacing(1.5, 2),
              borderRadius: theme.spacing(4),
              background: !annual
                ? theme.palette.primary.light
                : theme.palette.primary.contrastText,
            }}
            onClick={() => dispatch(changeAnnual(false))}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: theme.spacing(2),
                color: theme.palette.primary.main,
              }}
            >
              {selectors[language].monthly}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              padding: theme.spacing(1.5, 2),
              borderRadius: theme.spacing(4),
              background: annual
                ? theme.palette.primary.light
                : theme.palette.primary.contrastText,
            }}
            onClick={() => dispatch(changeAnnual(true))}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: theme.spacing(2),
                color: theme.palette.primary.main,
              }}
            >
              {selectors[language].annually}
            </Typography>
          </Box>
        </Box>
        {mobile ? (
          <>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: theme.spacing(-5),
                right: theme.spacing(0),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: theme.spacing(0.5, 1),
                  background: theme.palette.primary.light,
                  borderRadius: theme.spacing(0.5),
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                    fontSize: theme.spacing(1.5),
                    lineHeight: "15px",
                    color: theme.palette.primary.main,
                  }}
                >
                  {selectors[language].offer}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                top: theme.spacing(-4),
                right: theme.spacing(-6.5),
              }}
            >
              <Box sx={{ width: theme.spacing(5), height: theme.spacing(9) }}>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={arrowDiscountMobile}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: theme.spacing(-5),
              right: theme.spacing(-16),
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: theme.spacing(0.5, 1),
                background: theme.palette.primary.light,
                borderRadius: theme.spacing(0.5),
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(2.5),
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                  fontSize: theme.spacing(1.5),
                  lineHeight: "15px",
                  color: theme.palette.primary.main,
                }}
              >
                {selectors[language].offer}
              </Typography>
            </Box>

            <Box
              sx={{ width: theme.spacing(7.75), height: theme.spacing(6.25) }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={arrowDiscount}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: theme.spacing(145),
          margin: theme.spacing(5),
          [theme.breakpoints.down(1440)]: {
            width: "calc(100% - 18%)",
            margin: theme.spacing(5, 0),
          },
          [theme.breakpoints.down("md")]: {
            width: "calc(100% - 30%)",
            margin: theme.spacing(4, 0),
          },
          [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 64px)",
            margin: theme.spacing(3, 0),
          },
        }}
      >
        <Slider
          value={value}
          sx={{
            "& .MuiSlider-thumb": {
              borderRadius: theme.spacing(1),
              width: theme.spacing(5),
              height: theme.spacing(4),
              background: theme.palette.primary.contrastText,
            },
            "& .MuiSlider-valueLabel": {
              background: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
              top: theme.spacing(-1.5),
              [theme.breakpoints.down("sm")]: {
                ...left(),
              },
            },
          }}
          min={1}
          max={5}
          step={null}
          marks={[
            { value: 1, label: "" },
            { value: 2, label: "" },
            { value: 3, label: "" },
            { value: 4, label: "" },
            { value: 5, label: "" },
          ]}
          valueLabelDisplay="on"
          getAriaValueText={sliderSelectionFormat}
          valueLabelFormat={sliderSelectionFormat}
          onChange={handleChange}
          onChangeCommitted={updatePlan}
        />
      </Box>
    </>
  );
};
