import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import TagManager from "react-gtm-module";
import Base from "./app/layout/Base";
import applicationTheme from "./theme/applicationTheme";
import { Home } from "./app/components/pages/home/Home";
import { Contact } from "./app/components/pages/contact/Contact";
import { Pricing } from "./app/components/pages/pricing/Pricing";
import { ToS } from "./app/components/pages/tos/ToS";
import { Privacy } from "./app/components/pages/privacy/Privacy";

function App() {
  const globalTheme = createTheme(
    applicationTheme("purpleTheme", "light", "ltr")
  );

  const routing = createBrowserRouter([
    {
      path: "",
      element: <Base />,
      children: [
        {
          path: "",
          element: <Home />,
          index: true,
        },

        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/pricing",
          element: <Pricing />,
        },
        {
          path: "/tos",
          element: <ToS />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/*",
          element: <Navigate to="" />,
        },
      ],
    },
  ]);

  TagManager.initialize({
    gtmId: "GTM-PVV79GH",
    dataLayerName: "PageDataLayer",
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <RouterProvider router={routing} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
