import { more, once, three, twelve } from "../../entities/imageHelper";

export const info = {
  en: {
    title: "Fair pricing that scales with your needs",
    text: "Automation is priceless for a business, that is why we make it affordable.",
  },
  gr: {
    title: "Πλάνα που προσαρμόζονται στις ανάγκες σας",
    text: "Ο αυτοματισμός είναι ανεκτίμητος για την επιχείρησή σας, γι' αυτό τον κάνουμε προσιτό.",
  },
};
export const selectors = {
  en: {
    monthly: "Monthly",
    annually: "Annually",
    slider: (value: number) => `Up to ${value} products`,
    offer: "Save 10%",
    sliderOptions: [100, 500, 1000, 5000, 10000],
  },
  gr: {
    monthly: "Μηνιαία",
    annually: "Ετήσια",
    slider: (value: number) => `Έως και ${value} προϊόντα`,
    offer: "Κέρδος 10%",
    sliderOptions: [100, 500, 1000, 5000, 10000],
  },
};
export const offers = {
  en: {
    offers: [
      {
        title: "Starter",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/mo",
        yearly: "/yr",
        case: "Up to 100 products",
        selector: [
          { option: "Update per 24hrs", once: 49 },
          { option: "Update per 8hrs", three: 49 },
          { option: "Update per 2hrs", twelve: 49 },
        ],
      },
      {
        title: "Essential",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/mo",
        yearly: "/yr",
        case: "Up to 500 products",
        selector: [
          { option: "Update per 24hrs", once: 139 },
          { option: "Update per 8hrs", three: 199 },
          { option: "Update per 2hrs", twelve: 299 },
        ],
      },
      {
        title: "Teams",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/mo",
        yearly: "/yr",
        case: "Up to 1000 products",
        selector: [
          { option: "Update per 24hrs", once: 199 },
          { option: "Update per 8hrs", three: 399 },
          { option: "Update per 2hrs", twelve: 499 },
        ],
      },
      {
        title: "Professional",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/mo",
        yearly: "/yr",
        case: "Up to 5000 products",
        selector: [
          { option: "Update per 24hrs", once: 399 },
          { option: "Update per 8hrs", three: 599 },
          { option: "Update per 2hrs", twelve: 999 },
        ],
      },
      {
        title: "Enterprise",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/mo",
        yearly: "/yr",
        case: "Up to 10000 products",
        selector: [
          { option: "Update per 24hrs", once: 599 },
          { option: "Update per 8hrs", three: 899 },
          { option: "Update per 2hrs", twelve: 1999 },
        ],
      },
    ],
    button: "Get started",
    custom: {
      text: "If you can't find a plan that fits your company needs just contact us to help you.",
      title: "Custom",
      button: "Contact us for an offer",
    },
  },
  gr: {
    offers: [
      {
        title: "Starter",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/μήνα",
        yearly: "/έτος",
        case: "Έως και 100 προϊόντα",
        selector: [
          { option: "Ανανέωση κάθε 24 ώρες", once: 49 },
          { option: "Ανανέωση κάθε 24 ώρες", three: 49 },
          { option: "Ανανέωση κάθε 24 ώρες", twelve: 49 },
        ],
      },
      {
        title: "Essential",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/μήνα",
        yearly: "/έτος",
        case: "Έως και 500 προϊόντα",
        selector: [
          { option: "Ανανέωση κάθε 24 ώρες", once: 139 },
          { option: "Ανανέωση κάθε 8 ώρες", three: 199 },
          { option: "Ανανέωση κάθε 2 ώρες", twelve: 299 },
        ],
      },
      {
        title: "Teams",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/μήνα",
        yearly: "/έτος",
        case: "Έως και 1000 προϊόντα",
        selector: [
          { option: "Ανανέωση κάθε 24 ώρες", once: 199 },
          { option: "Ανανέωση κάθε 8 ώρες", three: 399 },
          { option: "Ανανέωση κάθε 2 ώρες", twelve: 499 },
        ],
      },
      {
        title: "Professional",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/μήνα",
        yearly: "/έτος",
        case: "Έως και 5000 προϊόντα",
        selector: [
          { option: "Ανανέωση κάθε 24 ώρες", once: 399 },
          { option: "Ανανέωση κάθε 8 ώρες", three: 599 },
          { option: "Ανανέωση κάθε 2 ώρες", twelve: 999 },
        ],
      },
      {
        title: "Enterprise",
        priceEuro: (value: number) => `€${value}`,
        monthly: "/μήνα",
        yearly: "/έτος",
        case: "Έως και 10000 προϊόντα",
        selector: [
          { option: "Ανανέωση κάθε 24 ώρες", once: 599 },
          { option: "Ανανέωση κάθε 8 ώρες", three: 899 },
          { option: "Ανανέωση κάθε 2 ώρες", twelve: 1999 },
        ],
      },
    ],
    button: "Επιλογή",
    custom: {
      text: "Εάν τα παραπάνω πλάνα δεν ταιριάζουν στις ανάγκες της επιχείρησής σας, επικοινωνήστε μαζί μας για μια εξατομικευμένη προσφορά.",
      title: "Custom",
      button: "Επικοινωνήστε μαζί μας",
    },
  },
};
export const features = {
  en: {
    title: "All features go in all our plans",
    features: [
      { title: "Stelth Monitoring", case: [] },
      { title: "OnDemand Scraping", case: [] },
      { title: "Dynamic Repricing", case: [] },
      { title: "Automatch Catalogue", case: [] },
      { title: "Flexible Targeting", case: [] },
      { title: "Market Reporting", case: [] },
      { title: "API Integrations", case: [] },
      { title: "MAP Breach Alerts", case: [] },
      { title: "Custom web Monitoring", case: [] },
      { title: "Available Support", case: [] },
      { title: "Greek MarketPlaces", case: [] },
      { title: "Brands' Analysis", case: [] },
    ],
    block1: [
      { title: "Stelth Monitoring", case: [] },
      { title: "OnDemand Scraping", case: [] },
      { title: "Dynamic Repricing", case: [] },
      { title: "Automatch Catalogue", case: [] },
    ],
    block2: [
      { title: "Flexible Targeting", case: [] },
      { title: "Market Reporting", case: [] },
      { title: "API Integrations", case: [] },
      { title: "MAP Breach Alerts", case: [] },
    ],
    block3: [
      { title: "Custom web Monitoring", case: [] },
      { title: "Available Support", case: [] },
      { title: "Greek MarketPlaces", case: [] },
      { title: "Brands' Analysis", case: [] },
    ],
    mblock1: [
      { title: "Stelth Monitoring", case: [] },
      { title: "OnDemand Scraping", case: [] },
      { title: "Dynamic Repricing", case: [] },
      { title: "Automatch Catalogue", case: [] },
      { title: "Flexible Targeting", case: [] },
      { title: "Market Reporting", case: [] },
    ],
    mblock2: [
      { title: "API Integrations", case: [] },
      { title: "MAP Breach Alerts", case: [] },
      { title: "Custom web Monitoring", case: [] },
      { title: "Available Support", case: [] },
      { title: "Greek MarketPlaces", case: [] },
      { title: "Brands' Analysis", case: [] },
    ],
  },
  gr: {
    title: "Όλες οι δυνατότητες, σε όλα μας τα πλάνα",
    features: [
      { title: "Καταγραφή Αγοράς", case: [] },
      { title: "OnDemand Καταγραφή", case: [] },
      { title: "Δυναμικό Repricing", case: [] },
      { title: "Automatch Καταλόγου", case: [] },
      { title: "Ελαστικότητα Στόχευσης", case: [] },
      { title: "Reports Αγοράς", case: [] },
      { title: "API Διασυνδέσεις", case: [] },
      { title: "Ειδοποίηση Παραβάσεων ΠΛΤ", case: [] },
      { title: "Custom web Καταγραφή", case: [] },
      { title: "Διαθέσιμη Υποστήριξη", case: [] },
      { title: "Ελληνικά MarketPlaces", case: [] },
      { title: "Ανάλυση ανά Brand", case: [] },
    ],
    block1: [
      { title: "Καταγραφή Αγοράς", case: [] },
      { title: "OnDemand Καταγραφή", case: [] },
      { title: "Δυναμικό Repricing", case: [] },
      { title: "Automatch Καταλόγου", case: [] },
    ],
    block2: [
      { title: "Ελαστικότητα Στόχευσης", case: [] },
      { title: "Reports Αγοράς", case: [] },
      { title: "API Διασυνδέσεις", case: [] },
      { title: "Ειδοποίηση Παραβάσεων ΠΛΤ", case: [] },
    ],
    block3: [
      { title: "Custom web Καταγραφή", case: [] },
      { title: "Διαθέσιμη Υποστήριξη", case: [] },
      { title: "Ελληνικά MarketPlaces", case: [] },
      { title: "Ανάλυση ανά Brand", case: [] },
    ],
    mblock1: [
      { title: "Καταγραφή Αγοράς", case: [] },
      { title: "OnDemand Καταγραφή", case: [] },
      { title: "Δυναμικό Repricing", case: [] },
      { title: "Automatch Καταλόγου", case: [] },
      { title: "Ελαστικότητα Στόχευσης", case: [] },
      { title: "Reports Αγοράς", case: [] },
    ],
    mblock2: [
      { title: "API Διασυνδέσεις", case: [] },
      { title: "Ειδοποίηση Παραβάσεων ΠΛΤ", case: [] },
      { title: "Custom web Καταγραφή", case: [] },
      { title: "Διαθέσιμη Υποστήριξη", case: [] },
      { title: "Ελληνικά MarketPlaces", case: [] },
      { title: "Ανάλυση ανά Brand", case: [] },
    ],
  },
};
export const updates = {
  en: {
    title: "How to decide on your plan's update interval:",
    updates: [
      {
        title: "Update per 24hrs",
        text: "Get market information every 24 hours, at the timing of your choosing. A starting plan that is a good match for small businesses.",
        icon: once,
      },
      {
        title: "Update per 8hrs",
        text: "Get market information every 8 hours, at the timings of your choosing. Suggested to small-medium businesses that check daily the prices on a good part of their catalogue.",
        icon: three,
      },
      {
        title: "Update per 2hrs",
        text: "Get market information every 2 hours, at the timings of your choosing. Suggested to businesses that follow a dynamic approach in regards to repricing thus, competitor intel is crucial for success. The right plan if you adjust prices every day.",
        icon: twelve,
      },
      {
        title: "Update less than 2hrs",
        text: "Get market information even every 30 minutes. We make it possible to get almost real time market intel on your competitor's moves. The right plan for bussinesses that operate in aggressive markets and are usually found among the top 3 positions in MarketPlaces' rankings.",
        icon: more,
      },
    ],
  },
  gr: {
    title:
      "Προσαρμόστε το πλάνο σας ανάλογα με τη δυναμική της επιχείρησής σας",
    updates: [
      {
        title: "Ανανέωση κάθε 24 ώρες",
        text: "Επιλέξτε να λαμβάνετε ανανεωμένες πληροφορίες για τον κατάλογό σας κάθε 24 ώρες. Αποτελεί την οικονομικότερη επιλογή και συνιστάται σε μικρές επιχειρήσεις.",
        icon: once,
      },
      {
        title: "Ανανέωση κάθε 8 ώρες",
        text: "Επιλέξτε να λαμβάνετε ανανεωμένες πληροφορίες για τον κατάλογο σας κάθε 8 ώρες. Συνιστάται σε μικρο-μεσαίες επιχειρήσεις που αλλάζουν τις τιμές σε ορισμένα προϊόντα ίσως και καθημερινά.",
        icon: three,
      },
      {
        title: "Ανανέωση κάθε 2 ώρες",
        text: "Επιλέξτε να λαμβάνετε ανανεωμένες πληροφορίες για τον κατάλογό σας κάθε 2 ώρες. Συνιστάται σε επιχειρήσεις που ανεξάρτητα από το μέγεθος τους, ακολουθούν μια ιδιαίτερα ανταγωνιστική στρατηγική και θέλουν να έχουν γνώση για κάθε κίνηση του ανταγωνισμού. Συνήθως προχωρούν σε αλλαγές στις τιμές κάθε ημέρα.",
        icon: twelve,
      },
      {
        title: "Ανανέωση συχνότερα από 2 ώρες",
        text: "Είναι δυνατό να λαμβάνετε ανανεωμένες πληροφορίες για τον κατάλογό σας ακόμα και κάθε 30 λεπτά. Συνιστάται σε επιχειρήσεις που βιώνουν πολύ έντονο ανταγωνισμό, συνήθως καταλαμβάνουν τις πρώτες 3 θέσεις στην κατάταξη των MarketPlaces και θέλουν να έχουν συνεχή ενημέρωση για τις κινήσεις της αγοράς.",
        icon: more,
      },
    ],
  },
};
