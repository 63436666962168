import React, { PropsWithChildren, useCallback } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { keyFeatures } from "../../../../../helpers/data/home";
import { FeatureCard } from "./FeatureCard";
import Swiper from "react-id-swiper";
import ReactPlayer from "react-player";

export const KeyFeatures = (props: PropsWithChildren) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(5),
      }}
      id="key"
    >
      <Typography
        sx={{
          [theme.breakpoints.down(460)]: {
            maxWidth: theme.spacing(35),
          },
          margin: theme.spacing(0, 0, 5),
          fontWeight: 800,
          fontSize: theme.spacing(4),
          lineHeight: 1,
          textAlign: "center",
        }}
      >
        {language === "en" ? "Key Features" : "Βασικά Χαρακτηριστικά"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          maxWidth: theme.spacing(141),
          justifyContent: "center",
          flexWrap: "wrap",
          "& >*": {
            margin: theme.spacing(3, 3),
            [theme.breakpoints.down("md")]: {
              margin: theme.spacing(3, 0),
            },
          },
        }}
      >
        {mobile ? (
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={24}
          >
            {keyFeatures[language].map((feature) => (
              <Box
                key={`${feature.title}`}
                component="div"
                sx={{
                  width: theme.spacing(38) + "!important",
                  height: theme.spacing(44) + "!important",
                  // [theme.breakpoints.down(1440)]: {
                  //   width: theme.spacing(35) + "!important",
                  // },
                }}
              >
                <FeatureCard feature={feature} />
              </Box>
            ))}
          </Swiper>
        ) : (
          <>
            {keyFeatures[language].map((feature) => (
              <Box
                key={`${feature.title}`}
                component="div"
                sx={{
                  width: theme.spacing(38) + "!important",
                  height: theme.spacing(44) + "!important",
                  // [theme.breakpoints.down(1440)]: {
                  //   width: theme.spacing(35) + "!important",
                  // },
                }}
              >
                <FeatureCard feature={feature} />
              </Box>
            ))}
          </>
        )}
      </Box>
      <Box
        sx={{
          height: "390px",
          width: "640px",
          marginTop: theme.spacing(4),
          [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "center",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            width: "320px",
            height: "400px",
          },
        }}
      >
        <ReactPlayer
          url="https://www.youtube.com/watch?v=5PaaeUhM73Q"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};
