export const header = {
  en: {
    features: "Features",
    pricing: "Pricing",
    contact: "Contact",
    trial: "7day free trial",
    languages: ["gr", "en"],
    banner: {
      contact: "Contact us",
      phone: "(+30)211 1989 293",
      timeslots: "Mon - Fri 10:00 - 18:00 (+3:00 UTC)",
    },
  },
  gr: {
    features: "Πλατφόρμα",
    pricing: "Τιμολόγηση",
    contact: "Επικοινωνία",
    trial: "Δωρεάν Δοκιμή",
    languages: ["gr", "en"],
    banner: {
      contact: "Επικοινωνήστε μαζί μας",
      phone: "(+30)211 1989 293",
      timeslots: "Καθημερινά 10:00 - 18:00",
    },
  },
};

export const footer = {
  en: {
    description: "Now you are informed about changes in your Market",
    features: { title: "Features", link: "" },
    keyFeatures: { title: "Key Features", link: "#key" },
    product: { title: "Products", link: "#products" },
    competitors: { title: "Competitors", link: "#competitors" },
    experience: { title: "About Us", link: "#experience" },
    pricing: { title: "Pricing", link: "/pricing" },
    legal: {
      title: "Policies",
      termsAndConditions: { title: "Terms and Conditions", link: "tos" },
      privacyPolicy: { title: "Privacy Policy", link: "privacy" },
    },
    contact: {
      title: "Contact us",
    },
    newsLetterError: {
      value: "Please type a valid email",
    }
  },
  gr: {
    description:
      "Πιάστε τον παλμό της αγοράς και κερδίστε πλεονέκτημα στα MarketPlaces",
    features: { title: "Πλατφόρμα", link: "" },
    keyFeatures: { title: "Key Features", link: "#key" },
    product: { title: "Προϊόντα", link: "#products" },
    competitors: { title: "Ανταγωνιστές", link: "#competitors" },
    experience: { title: "Σχετικά με εμάς", link: "#experience" },
    pricing: { title: "Τιμολόγηση", link: "/pricing" },
    legal: {
      title: "Πολιτική Απορρήτου",
      termsAndConditions: { title: "Όροι & Προϋποθέσεις", link: "/tos" },
      privacyPolicy: { title: "Πολιτική Απορρήτου", link: "/privacy" },
    },
    contact: {
      title: "Επικοινωνία",
    },
    newsLetterError: {
      value: "Παρακαλώ εισάγετε ένα εγκυρο email.",
    }
  },
};
