import React, { createContext, useContext, useReducer } from "react";
import { AppState, Language } from "../../../entities/types";

interface IProps {
  children: React.ReactNode;
}

export const changeLanguage = (payload: Language) =>
  ({
    type: "CHANGE_LANGUAGE",
    payload,
  } as const);

export const changeSelectedPlan = (
  payload: "starter" | "essential" | "teams" | "professional" | "enterprise"
) =>
  ({
    type: "CHANGE_PLAN",
    payload,
  } as const);

export const changeSelectedPlanSlider = (
  payload: 100 | 500 | 1000 | 5000 | 10000
) =>
  ({
    type: "CHANGE_PLAN_SLIDER",
    payload,
  } as const);

export const changeAnnual = (payload: boolean) =>
  ({
    type: "CHANGE_ANNUAL",
    payload,
  } as const);

export const changeScrapes = (payload: "once" | "three" | "twelve") =>
  ({
    type: "CHANGE_SCRAPES",
    payload,
  } as const);

export const updateMessage = (payload: string) =>
  ({
    type: "UPDATE_MESSAGE",
    payload,
  } as const);

export const updateSupportMessage = (payload: string) =>
  ({
    type: "UPDATE_MESSAGE_SUPPORT",
    payload,
  } as const);

export const resetMessage = () =>
  ({
    type: "RESET_MESSAGE",
  } as const);

type Action =
  | ReturnType<typeof changeLanguage>
  | ReturnType<typeof changeSelectedPlan>
  | ReturnType<typeof changeSelectedPlanSlider>
  | ReturnType<typeof changeAnnual>
  | ReturnType<typeof changeScrapes>
  | ReturnType<typeof updateMessage>
  | ReturnType<typeof updateSupportMessage>
  | ReturnType<typeof resetMessage>;

type ContextType = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};

const initialState: AppState = {
  language: "gr",
  plan: "teams",
  annual: true,
  products: 1000,
  scrapes: "three",
  message: "",
  sales: false,
  messageSupport: "",
  support: false,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return { ...state, language: action.payload };
    case "CHANGE_PLAN":
      return { ...state, plan: action.payload };
    case "CHANGE_PLAN_SLIDER":
      return { ...state, products: action.payload };
    case "CHANGE_ANNUAL":
      return { ...state, annual: action.payload };
    case "CHANGE_SCRAPES":
      return { ...state, scrapes: action.payload };
    case "UPDATE_MESSAGE":
      return {
        ...state,
        message: action.payload,
        messageSupport: "",
        sales: true,
        support: false,
      };
    case "UPDATE_MESSAGE_SUPPORT":
      return {
        ...state,
        message: "",
        messageSupport: action.payload,
        support: true,
        sales: false,
      };
    case "RESET_MESSAGE":
      return {
        ...state,
        message: "",
        messageSupport: "",
        sales: false,
        support: false,
      };
    default:
      return { ...state };
  }
};

const Base = createContext<ContextType>({
  state: initialState,
  dispatch: () => {},
});

const BaseLayoutProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Base.Provider value={{ state, dispatch }}>{children}</Base.Provider>;
};

export const useAppContext = () => {
  return useContext(Base);
};

export default BaseLayoutProvider;
