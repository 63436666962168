import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as FullName } from "../helpers/assets/name_icon.svg";
import { ReactComponent as Telephone } from "../helpers/assets/phone_icon.svg";
import { ReactComponent as Website } from "../helpers/assets/web_icon.svg";
import { ReactComponent as Email } from "../helpers/assets/mail_icon.svg";
import { ReactComponent as FeatureCheck } from "../helpers/assets/feature_check.svg";
import { ReactComponent as PhoneCall } from "../helpers/assets/phone-call.svg";
import { ReactComponent as Address } from "../helpers/assets/bx_building-house.svg";
import { ReactComponent as Calendar } from "../helpers/assets/calendar.svg";
import { ReactComponent as MenuMobile } from "../helpers/assets/menu_mobile.svg";
import { ReactComponent as CancelModal } from "../helpers/assets/cancel_icon.svg";

export function FullNameIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={FullName}
      {...props}
    />
  );
}
export function TelePhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={Telephone}
      {...props}
    />
  );
}
export function WebsiteIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={Website}
      {...props}
    />
  );
}
export function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={Email}
      {...props}
    />
  );
}
export function PhoneCallIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.contrastText } }}
      component={PhoneCall}
      {...props}
    />
  );
}
export function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.secondary.main } }}
      component={Calendar}
      {...props}
    />
  );
}
export function AddressIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      // sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={Address}
      {...props}
    />
  );
}
export function FeatureCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={FeatureCheck}
      {...props}
    />
  );
}
export function MenuMobileIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={MenuMobile}
      {...props}
    />
  );
}
export function CancelModalIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      sx={{ "&>*": { fill: (theme) => theme.palette.input.main } }}
      component={CancelModal}
      {...props}
    />
  );
}
