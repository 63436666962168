import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useAppContext } from "../../../../layout/AppProvider/BaseProvider";
import { labels } from "../../../../../helpers/data/home";

interface IProps {
  color: "orange" | "blue" | "red" | "green";
}

export const ColorLabel = (props: IProps) => {
  const { state } = useAppContext();
  const { language } = state;
  const theme = useTheme();

  switch (props.color) {
    case "orange":
      return (
        <Box
          sx={{
            background: theme.palette.warning.light,
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(0.75, 1),
          }}
        >
          <Typography variant="h6" color="#E3844D" textAlign="center">
            {labels[language].demo}
          </Typography>
        </Box>
      );
    case "blue":
      return (
        <Box
          sx={{
            background: theme.palette.primary.light,
            borderRadius: theme.spacing(0.5),
            width: theme.spacing(13.5),
            padding: theme.spacing(0.75, 1),
          }}
        >
          <Typography variant="h6" color="primary.main" textAlign="center">
            {labels[language].products}
          </Typography>
        </Box>
      );
    case "red":
      return (
        <Box
          sx={{
            background: theme.palette.error.light,
            borderRadius: theme.spacing(0.5),
            width: theme.spacing(13.5),
            padding: theme.spacing(0.75, 1),
          }}
        >
          <Typography variant="h6" color="#D43C76" textAlign="center">
            {labels[language].competitors}
          </Typography>
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            background: theme.palette.success.light,
            borderRadius: theme.spacing(0.5),
            width: theme.spacing(15.5),
            padding: theme.spacing(0.75, 1),
          }}
        >
          <Typography variant="h6" color="#30A590" textAlign="center">
            {labels[language].experience}
          </Typography>
        </Box>
      );
  }
};
