import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formInfo } from "../../../../../helpers/data/contact";
import emailjs from "@emailjs/browser";
import {
  resetMessage,
  useAppContext,
} from "../../../../layout/AppProvider/BaseProvider";
import {
  EmailIcon,
  FullNameIcon,
  TelePhoneIcon,
  WebsiteIcon,
} from "../../../../../entities/iconHelper";
import { capitalizeFirstLetter } from "../../../utils";

interface FormState {
  department: string;
  name: string;
  email: string;
  phone: string;
  website: string;
  message: string;
  check: boolean;
}

export const Form = (props: PropsWithChildren) => {
  const { state, dispatch } = useAppContext();
  const { language, sales, message, support, messageSupport } = state;
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formState, setFormState] = useState<FormState>({
    department: sales ? "sales" : support ? "support" : "info",
    name: "",
    email: "",
    phone: "",
    website: "",
    message: message,
    check: false,
  });

  const handleChangeDept = (event: SelectChangeEvent) => {
    setFormState((prev) => {
      return { ...prev, department: event.target.value as string };
    });
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === "check") {
      return setFormState((prev) => {
        return { ...prev, [event.target.id]: event.target.checked as boolean };
      });
    }
    setFormState((prev) => {
      return { ...prev, [event.target.id]: event.target.value as string };
    });
  };
  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => {
      return { ...prev, message: event.target.value as string };
    });
  };

  useEffect(() => {
    recaptchaRef.current?.reset();
  }, []);

  useEffect(() => {
    if (support)
      return setFormState((prev) => {
        return { ...prev, message: messageSupport, department: "support" };
      });
    if (sales)
      return setFormState((prev) => {
        return { ...prev, message, department: "sales" };
      });
  }, [sales, support, messageSupport, message]);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const recaptchaValue = recaptchaRef.current?.getValue();
    switch (formState.department) {
      case "support":
        //Support
        emailjs
          .send(
          "service_pm_info",
          "template_internal_suppor",
          {
            name: formState.name,
            website: formState.website,
            phone: formState.phone,
            message: formState.message,
            to_email: formState.email,
              // "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
          )
          .then(
            async () => {
              // console.log("SUCCESS!", response.status, response.text);
        //Customer
              emailjs
                .send(
          "service_pm_info",
          "template_customer",
          {
            name: formState.name,
            message: formState.message,
            to_email: formState.email,
            "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
                )
                .then(
                  () => {
                    // console.log("SUCCESS!", response.status, response.text);
                    window.location.reload();
                  },
                  () => {
                    // console.log("FAILED...", error);
                  }
                );
            },
            () => {
              // console.log("FAILED...", error);
            }
        );
        break;
      case "sales":
        //Sales
        emailjs
          .send(
          "service_pm_info",
          "template_internal_sales",
          {
            name: formState.name,
            to_email: formState.email,
            annual: sales ? state.annual : "",
            plan_name: sales ? state.plan : "",
            products: sales ? state.products : "",
            scrapes: sales ? state.scrapes : "",
            phone: formState.phone,
            website: formState.website,
            message: formState.message,
              // "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
          )
          .then(
            async () => {
              // console.log("SUCCESS!", response.status, response.text);
        //Customer
              emailjs
                .send(
          "service_pm_info",
          "template_customer",
          {
            name: formState.name,
            message: formState.message,
            to_email: formState.email,
            "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
                )
                .then(
                  () => {
                    // console.log("SUCCESS!", response.status, response.text);
                    window.location.reload();
                  },
                  () => {
                    // console.log("FAILED...", error);
                  }
                );
            },
            () => {
              // console.log("FAILED...", error);
            }
        );
        break;
      default:
        //Support
        emailjs
          .send(
          "service_pm_info",
          "template_internal_suppor",
          {
            name: formState.name,
            website: formState.website,
            phone: formState.phone,
            message: formState.message,
            to_email: formState.email,
              // "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
          )
          .then(
            async () => {
              // console.log("SUCCESS!", response.status, response.text);
        //Customer
              emailjs
                .send(
          "service_pm_info",
          "template_customer",
          {
            name: formState.name,
            message: formState.message,
            to_email: formState.email,
            "g-recaptcha-response": recaptchaValue,
          },
          "SLsmNfB-D5OSPd6WE"
                )
                .then(
                  () => {
                    // console.log("SUCCESS!", response.status, response.text);
                    window.location.reload();
                  },
                  () => {
                    // console.log("FAILED...", error);
                  }
                );
            },
            () => {
              // console.log("FAILED...", error);
            }
        );
        break;
    }
    dispatch(resetMessage());
  };

  return (
    <Paper
      elevation={1}
      sx={{
        padding: theme.spacing(4),
        width: theme.spacing(62),
        [theme.breakpoints.down(1440)]: {
          width: "auto",
        },
        [theme.breakpoints.down("md")]: {
          width: "calc(100% - 48px)",
          padding: theme.spacing(4, 3),
        },
      }}
    >
      <form onSubmit={onSubmit}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "29px",
          }}
        >
          {formInfo[language].title}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            marginTop: theme.spacing(2),
          }}
        >
          {formInfo[language].text}
        </Typography>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              marginTop: theme.spacing(2),
            }}
          >
            {formInfo[language].department.title}
          </Typography>
          <Select
            id="department-select"
            variant="filled"
            fullWidth
            sx={{
              margin: theme.spacing(3, 0, 1.5),
              "& .MuiSelect-icon": {
                color: theme.palette.primary.dark,
              },
              "& >div": {
                padding: theme.spacing(2, 4, 2.125, 1.5),
              },
            }}
            label="Department"
            IconComponent={ExpandMoreIcon}
            value={formState.department}
            onChange={handleChangeDept}
          >
            {formInfo[language].department.options.map((department) => (
              <MenuItem key={`${department}`} value={department}>
                {capitalizeFirstLetter(department)}
              </MenuItem>
            ))}
          </Select>
          <Divider sx={{ margin: theme.spacing(1, 0) }} />
          <TextField
            id="name"
            variant="filled"
            fullWidth
            required
            sx={{
              margin: theme.spacing(1.5, 0),
            }}
            InputProps={{ endAdornment: <FullNameIcon /> }}
            label={formInfo[language].form.name}
            onChange={handleChange}
          />
          <TextField
            id="email"
            variant="filled"
            fullWidth
            required
            type="email"
            sx={{
              margin: theme.spacing(1.5, 0),
            }}
            InputProps={{ endAdornment: <EmailIcon /> }}
            label={formInfo[language].form.email}
            onChange={handleChange}
          />
          <TextField
            id="phone"
            variant="filled"
            fullWidth
            sx={{
              margin: theme.spacing(1.5, 0),
            }}
            InputProps={{ endAdornment: <TelePhoneIcon /> }}
            label={formInfo[language].form.phone}
            onChange={handleChange}
          />
          <TextField
            id="website"
            variant="filled"
            fullWidth
            sx={{
              margin: theme.spacing(1.5, 0),
            }}
            InputProps={{ endAdornment: <WebsiteIcon /> }}
            label={formInfo[language].form.website}
            onChange={handleChange}
          />
          <TextField
            id="message"
            variant="filled"
            multiline
            sx={{
              margin: theme.spacing(2, 0),
            }}
            value={formState.message}
            fullWidth
            label={formInfo[language].form.message.label}
            onChange={handleChangeMessage}
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  margin: theme.spacing(0, 0, 0, 1),
                  [theme.breakpoints.down("md")]: {
                    margin: theme.spacing(0, 0, 2.5, 1),
                  },
                }}
                id="check"
                required
                checked={formState.check}
                onChange={handleChange}
              />
            }
            label={
              <Typography>
                {formInfo[language].form.check.text}{" "}
                {formInfo[language].form.check.content}
              </Typography>
            }
          />
          <Box
            sx={{
              margin: theme.spacing(2, 0),
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="normal"
              sitekey="6Lfa8FgiAAAAAL44CQ0syC3ywUf_1aHZNGwRur1L"
              // size="invisible"
              // sitekey="6LcmkkMiAAAAAPMekQawPxE8ygie8RzxJ2rr7XCO"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: theme.spacing(4, 0, 0),
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth={mobile ?? true}
            type="submit"
          >
            {formInfo[language].button}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};
